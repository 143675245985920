import React, { useState } from "react";
import logoLcDark from "@/assets/lc-logo-dark.png";
import { Link } from "react-router-dom";
import { ArrowLeft, EyeIcon, EyeOff, Quote } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Toaster } from "sonner";
import { userLogin } from "@/api/usersService";
// import Cookies from "js-cookie";

export const Login: React.FC = () => {
	/* User fields state */
	const [user, setUser] = useState<UserLogin>({
		email: "",
		password: "",
	});

	/* State for showing password (true -> show | false -> hide) */
	const [showPassword, setShowPassword] = useState(false);

	/* To navigate between components */
	// const navigate = useNavigate();

	/* Token state (stock in storage) */
	// const [token, setToken] = useState(Cookies.get("token"));

	/* Check if login id are wrong */
	const [isWrong] = useState(false);

	/** To show password in field */
	const handleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	/**
	 * @function () Login at the app (redirect on dashboard if token is correct)
	 */
	const handleSubmit = async () => {
		userLogin(user)
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			{/* Toaster Init */}
			<Toaster position="top-center" richColors closeButton />

			{/* Header */}
			<div className="fixed w-screen lg:flex md:justify-between justify-center items-center px-10 py-5 top-0 lg:top-4">
				<Link to={"/"} className=" text-primary lg:text-muted flex gap-1 w-full">
					<ArrowLeft /> Retour a l'accueil
					{/* <img src={logoLcLight} alt="" className="w-36" /> */}
					{/* <p className={`font-bold text-light text-sm ms-2`}>beta</p> */}
				</Link>
			</div>

			{/* Container */}
			<div className="max-h-screen grid lg:grid-cols-2">
				{/* ===== Left Part ===== */}
				<div className="min-h-screen bg-primary text-light hidden lg:flex items-end">
					{/* Quotes */}
					<div className="flex flex-col gap-3 p-8 text-xl">
						<Quote size={72} strokeWidth={1} className="opacity-30" />
						<p className="font-thin">Cet outil m’a permis de développer de manière très significative mon activité, j’ai pu accroitre ma visibilité ainsi que mon organisation pour des résultats surprenants.</p>
						<p>
							<span className="font-bold">Valérie DUPONT</span> - Avocate
						</p>
					</div>
				</div>

				{/* ===== Right Part ===== */}
				<div className="min-h-screen flex flex-col justify-center items-center gap-5 px-10 md:px-0">
					<img src={logoLcDark} alt="" className="w-52 block lg:hidden pb-5 " />
					<div className="text-center space-y-2">
						<h1 className="text-dark-primary text-2xl font-bold tracking-tighter">Connexion à mon compte</h1>
						<p className="text-zinc-500  text-center mx-auto">Entrer vos informations accedez à votre compte.</p>
					</div>

					<div>
						<div className="flex flex-col items-center space-y-3">
							{/* Mail */}
							<div className="flex gap-3 min-w-96">
								<Input className={isWrong ? "border border-red-500" : ""} placeholder="nom@exemple.fr" type="email" required onChange={(e) => setUser((prevUser: UserLogin) => ({ ...prevUser, email: e.target.value }))} />
							</div>

							{/* Pasword */}
							<div className="flex flex-col gap-3 w-full">
								<div className="flex">
									<Input className={isWrong ? "border border-red-500" : ""} placeholder="•••••••••" type={showPassword ? "text" : "password"} required onChange={(e) => setUser((prevUser: UserLogin) => ({ ...prevUser, password: e.target.value }))} />
									<Button className="bg-transparent hover:bg-transparent  p-0 " onClick={() => handleShowPassword()}>
										{showPassword ? <EyeOff className="text-zinc-900 -translate-x-6 absolute" size={20} /> : <EyeIcon className="text-zinc-900 -translate-x-6 absolute" size={20} />}
									</Button>
								</div>
							</div>

							<div className="w-full">
								{/* Button to Login */}
								<Button className="w-full" onClick={handleSubmit}>
									Se connecter
								</Button>

								{/* OU */}
								<div className="flex flex-row justify-center items-center mx-auto">
									<hr className="w-full h-px my-6 border-1 border-gray" />
									<span className="px-8 font-semibold text-[12px] text-zinc-500">OU</span>
									<hr className="w-full h-px my-6 border-1 border-gray" />
								</div>

								{/* Link to register */}
								<Button variant={"outline"} className="w-full" onClick={() => (window.location.href = "/register")}>
									S'inscrire
								</Button>
							</div>

							{/* <div className="flex flex-row justify-center items-center -mx-56">
							<hr className="w-28 h-px my-6 bg-gray-200 border-0 dark:bg-gray-700"	 />
							<span className="px-8 font-semibold text-[12px] text-zinc-500 bg-light">OU CONTINUEZ AVEC</span>
							<hr className="w-28 h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
						</div> */}

							{/* Button FranceConnect */}
							{/* <Button className="flex gap-5 px-12 py-8 ">
							<img src={franceConnect} alt="" />
							<div className="flex flex-col">
								<span className="font-normal">S'identifier avec</span>
								<span className="font-bold">FranceConnect</span>
							</div>
						</Button> */}

							{/* Accept CGU and Privacy Policy */}
							{/* <div className="">
								<p className="text-zinc-500 text-center">
									En cliquant sur suivant, vous acceptez
									<Link to={"/"} className="underline px-1">
										les conditions générales d’utilisation
									</Link>
									et la
									<Link to={"/"} className="underline px-1">
										politique de confidentialitée.
									</Link>
								</p>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
