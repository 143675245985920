import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Header } from "@/components/header/Header";
import { Separator } from "@/components/ui/separator";
import { CalendarRange, ChevronsUp, LineChart, UserRoundSearch } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { KeyNumbers } from "@/components/landing/KeyNumbers";
import { Partners } from "@/components/landing/Partners";
import { FeaturesGroup } from "@/components/landing/FeaturesGroups";
import { FAQSection } from "@/components/landing/FAQSection";
import { Contact } from "@/components/landing/Contact";
// import { Security } from "@/components/landing/Security";
import lawyerHero from "@/components/landing/images/lawyer-hero-section.png";
import appPreviewPro from "@/components/landing/images/app-preview-pro.svg";
import { Button } from "@/components/ui/button";
import { isUserAuthentificated, features } from "@/lib/data";

export const LandingClient: React.FC = () => {
	// Profession's names for auto change effect
	const professions = ["avocat.", "notaire.", "juriste."];
	const [currentProfessionIndex, setCurrentProfessionIndex] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentProfessionIndex((prevIndex) => (prevIndex + 1) % professions.length);
		}, 2500); // Change le mot toutes les 3 secondes

		return () => clearInterval(interval);
	}, []); // Effect se déclenche une seule fois après le montage du composant

	return (
		<>
			{/*===== Section 0 : Hero section + Header =====*/}
			<div className="bg-primary">
				<Header view="client" />

				<div className="flex flex-row lg:flex-row justify-center items-center gap-8 px-2 md:px-4 pt-32 pb-8 md:pb-12 xl:pb-0 overflow-hidden relative transition-all z-[99] md:overflow-visible xl:justify-evenly lg:items-center">
					{/* left */}
					<div className="flex flex-col gap-6 p-4 lg:px-12 w-full xl:w-5/12">
						<div className="space-y-1">
							<p className={`font-bold text-gray`}>VOS DROITS, NOTRE PRIORITE</p>
							<div>
								<h1 className="text-4xl xl:text-5xl font-bold text-light">Trouvez un rendez-vous avec un </h1>
								{professions.map((profession, index) => (
									<h1 key={index} className={`text-4xl xl:text-5xl font-bold text-secondary ${currentProfessionIndex === index ? "animate-slide-down block" : "hidden"}`}>
										{profession}
									</h1>
								))}{" "}
							</div>
						</div>

						{/* span change (effect) */}
						<p className="font-light text-muted w-full">Rencontrez rapidement des avocats, juristes, et notaires qualifiés. Simplifiez vos démarches juridiques, que vous soyez un particulier ou un professionnel.</p>
						{/* <SearchBarPro value={searchResults} setValue={setSearchResults} /> */}
						{/* <SearchBar value={searchResults} setValue={setSearchResults} horizontal view="landing" /> */}
						<div className="flex flex-col md:flex-row gap-3">
							<Button className="bg-secondary text-light p-6 hover:bg-secondary/90 font-semibold whitespace-nowrap" onClick={() => (isUserAuthentificated() ? navigate("/dashboard") : navigate("/login"))}>
								Commencez dès maintenant
							</Button>
							<Button className="bg-primary text-light p-6 border border-light/20 hover:bg-light/5 font-semibold text-nowrap" onClick={() => (window.location.href = "/pros")}>
								Consulter l'agenda juridique
							</Button>
							{/* <Button className="bg-primary">Consulter l'agenda juridique</Button> */}
						</div>
					</div>

					{/* right */}
					<img src={lawyerHero} alt="lawyerHero" className="hidden translate-y-20 lg:flex animate-float w-[400px] xl:w-[600px]" />
				</div>
			</div>

			{/*===== Section 1 : Description de l'application + chiffres clés =====*/}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<FeaturesGroup features={features} />
				<KeyNumbers />
			</div>

			{/*===== Section 2 : Etapes de prise de rendez vous + Call to action inscription =====*/}
			<div className="py-20 px-6 md:px-0 2xl:px-64 space-y-10 transition-all flex flex-col justify-center items-center bg-primary">
				<div className="text-center">
					<p className={`font-bold text-gray uppercase`}>En 3 étapes</p>
					<h1 className="text-3xl xl:text-4xl font-bold text-light">Soyez rapidement soutenu. </h1>
				</div>
				{/* Progress Bar between steps */}
				<Progress className="hidden absolute h-1 w-3/5 lg:block -translate-y-3" />
				{/* Steps explication */}
				<div className="grid lg:grid-cols-3 gap-8 lg:gap-16  ">
					{/* Step 1 */}
					<div className="flex flex-col md:flex-row items-center gap-4 px-5 bg-primary z-10 w-full">
						<div className="flex justify-center items-center border border-light p-3 rounded-full w-16 h-16">
							<UserRoundSearch size={42} className="text-secondary" />
						</div>
						<div className="text-light text-center md:text-start">
							<h1 className="font-semibold text-xl">1. Trouvez un professionnel</h1>
							<p className="font-light">Trouvez un professionnel près de chez vous ou à distance.</p>
						</div>
					</div>

					{/* Step 2 */}
					<div className="flex flex-col md:flex-row items-center gap-4 px-5 bg-primary z-10 w-full">
						<div className="flex justify-center items-center border border-light p-3 rounded-full w-16 h-16">
							<CalendarRange size={42} className="text-secondary" />
						</div>
						<div className="text-light text-center md:text-start">
							<h1 className="font-semibold text-xl">2. Rendez-vous connectés</h1>
							<p className="font-light">Prenez rendez-vous et connectez-vous à des experts juridique.</p>
						</div>
					</div>

					{/* Step 3 */}
					<div className="flex flex-col md:flex-row items-center gap-4 px-5 bg-primary z-10 w-full">
						<div className="flex justify-center items-center border border-light p-3 rounded-full w-16 h-16">
							<LineChart size={42} className="text-secondary" />
						</div>
						<div className="text-light text-center md:text-start">
							<h1 className="font-semibold text-xl">3. Rapport et suivi</h1>
							<p className="font-light">Ayez un suivi et un rapport détaillé de vos rendez-vous passés.</p>
						</div>
					</div>
				</div>

				{/* CTA */}
				<NavLink className={`font-bold border-2 py-2 px-12 rounded-lg transition-all bg-light hover:bg-light/90 border-light w-full md:w-fit text-center `} to={"/register"}>
					Je m'inscris
				</NavLink>
			</div>

			{/*===== Section 3 : Réseau de partenaires =====*/}
			<div className="py-10 w-5/6 md:w-3/4 xl:w-2/3 mx-auto pb-0 space-y-10 transition-all">
				<Partners />

			</div>

			{/*===== Section 4 : Protection de vos données =====*/}
			{/* <div className="py-20 mx-auto space-y-10 transition-all">
				<Security />
			</div> */}

			{/*===== Section 5 : Vous êtes Professionnels ? =====*/}
			<div className="py-20 px-8 2xl:px-64 space-y-10 transition-all bg-primary text-light">
				<div className="text-center">
					<p className={`font-bold text-gray uppercase`}>Pour les professionnels</p>
					<h1 className="text-3xl xl:text-4xl font-bold">Pour de meilleures affaires. </h1>
				</div>
				<div className="flex flex-col-reverse xl:flex-row justify-between gap-28 xl:gap-10 xl:py-12">
					{/* left */}
					<div className="flex flex-col justify-center 2xl:items-start gap-10">
						<ul className="space-y-8">
							<li className="flex gap-5">
								<div className="flex justify-center items-center border border-light rounded-full w-10 h-10">
									<ChevronsUp size={32} className="text-secondary" />
								</div>
								<div>
									<h1 className="font-bold text-xl">Une meilleure gestion de vos dossiers et de vos cabinets.</h1>
									<p className="text-gray">Gérez plus facilement vos dossiers judiciaires et vos cabinets a l’aide des différentes fonctionnalités.</p>
								</div>
							</li>
							<li className="flex gap-5">
								<div className="flex justify-center items-center border border-light rounded-full w-10 h-10">
									<ChevronsUp size={32} className="text-secondary" />
								</div>
								<div>
									<h1 className="font-bold text-xl">Une interface simple et épurée.</h1>
									<p className="text-gray">Un design pensée et une interface travaillée pour les professionnels afin de faciliter la prise en main et la qualité de leur services.</p>
								</div>
							</li>
							<li className="flex gap-5">
								<div className="flex justify-center items-center border border-light rounded-full w-10 h-10">
									<ChevronsUp size={32} className="text-secondary" />
								</div>
								<div>
									<h1 className="font-bold text-xl">De nombreuses fonctionnalités</h1>
									<p className="text-gray">Des fonctionnalités tel qu’un tableau de bord, un agenda interconnecte, un système de visioconférence sont présentes au sein de l’outil. </p>
								</div>
							</li>
							<li className="flex gap-5">
								<div className="flex justify-center items-center border border-light rounded-full w-10 h-10">
									<ChevronsUp size={32} className="text-secondary" />
								</div>
								<div>
									<h1 className="font-bold text-xl">Une meilleure portabilité et une meilleure flexibilité </h1>
									<p className="text-gray">La plateforme est facile a prendre en main et s’adresse a plusieurs professionnels du juridique. Elle fait gagner en temps de travail et en flexibilité. </p>
								</div>
							</li>
						</ul>

						{/* CTA */}
						<NavLink className={`font-bold border-2 py-2 px-12 rounded-lg transition-all bg-secondary hover:bg-secondary/90 border-secondary text-center`} to={"/pro"}>
							Je suis Professionnel
						</NavLink>
					</div>

					{/* right */}
					<img src={appPreviewPro} alt="appPreviewPro" className="animate-float w-11/12 md:w-[550px] xl:w-[700px] self-center" />
				</div>
			</div>

			{/*======= Section 6 : FAQ =======*/}
			{/* FAQ */}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<FAQSection />
			</div>

			<Separator className="border border-dashed border-primary/50 w-full" />

			{/*===== Section 7 : Contact =====*/}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<Contact />
			</div>

			{/*===== Section 8 : Footer =====*/}
			{/* <Footer /> */}
		</>
	);
};
