import React from "react";

export const KeyNumbers: React.FC = () => {
	return (
		<div className="grid sm:grid-cols-3 justify-items-center content-center gap-6 sm:gap-10 md:gap-0 py-4 md:p-8 md:bg-muted/30 md:border border-gray rounded-full">
			<div className="text-center w-full">
				<h1 className="text-secondary text-3xl font-bold">+100</h1>
				<p className="font-medium text-lg">professionnels</p>
			</div>
			<div className="text-center w-full">
				<h1 className="text-secondary text-3xl font-bold">+50</h1>
				<p className="font-medium text-lg">partenaires</p>
			</div>
			<div className="text-center w-full">
				<h1 className="text-secondary text-3xl font-bold">+600</h1>
				<p className="font-medium text-lg">clients</p>
			</div>
		</div>
	);
};
