import axios from "axios";

export const getRegions = async () => {
	try {
		const response = await axios.get("https://geo.api.gouv.fr/regions");
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
