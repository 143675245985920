import React, { useState, useEffect } from "react";
import { getAllPro } from "@/api/prosService";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { HiInformationCircle, HiMapPin, HiOutlineIdentification, HiPhone } from "react-icons/hi2";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { customPagination } from "@/lib/theme";
// import { useNavigate } from "react-router-dom";
import { Pagination } from "flowbite-react";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { getUserData } from "@/api/usersService";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { HiMail, HiOfficeBuilding } from "react-icons/hi";
import { ArrowLeft, AlertTriangle, X } from "lucide-react";
import { Label } from "../ui/label";
import moment from "moment";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { createAppointments } from "@/api/appointmentsServices";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export const AllProsDirectory: React.FC = () => {
	// const navigate = useNavigate();
	const [pros, setPros] = useState<Pro[]>([]);
	const [user, setUser] = useState<UserInfo>();

	// On responsive, hide side view pro appointment and infos
	const [viewSidePro, setViewSidePro] = useState(false);

	// Open side view to see pro info and take appointment
	const [seePro, setSeePro] = useState({
		proSelected: false,
		pro_id: 0,
	});

	const [stepAppointment, setStepAppointment] = useState(false);

	// Payload for appointment creation
	const [appointmentData, setAppointmentData] = useState<AppointmentsCreate>({
		client_id: 0,
		pro_id: 0,
		date: moment().format("LL"),
		starting_at: moment().format("YYYY-MM-DD HH:mm:ss"), // Utilisation de moment.js pour obtenir la date et l'heure actuelles au format souhaité
		is_visio: 0,
		reason: "",
	});

	// State of search value
	const [searchResults, setSearchResults] = useState("");
	// const [searchBy, setSearchBy] = useState("");

	// Pagination states
	const [itemsPerPage] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const onPageChange = (page: number) => setCurrentPage(page); // Change current page
	const prosResult = pros.filter((pro) => {
		return (
			pro.firstname.toLowerCase().includes(searchResults.toLowerCase()) ||
			pro.lastname.toLowerCase().includes(searchResults.toLowerCase()) ||
			(pro.speciality && pro.speciality.toLowerCase().includes(searchResults.toLowerCase())) ||
			(pro.adress && pro.adress.toLowerCase().includes(searchResults.toLowerCase())) ||
			(pro.city && pro.city.toLowerCase().includes(searchResults.toLowerCase())) ||
			(pro.country && pro.country.toLowerCase().includes(searchResults.toLowerCase()))
		);
	});

	// Displaying in current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const prosSliced = prosResult ? prosResult.slice(startIndex, endIndex) : [];

	const handleSetAppointment = (pro: any, pro_id: number) => {
		setSeePro({ proSelected: true, pro_id: pro.id });
		user && setAppointmentData((prev) => ({ ...prev, client_id: user.id }));
		pro && setAppointmentData((prev) => ({ ...prev, pro_id: pro_id }));
	};

	// Fonction pour mettre à jour l'heure de début de rendez-vous
	const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const timeValue = e.target.value; // Obtient la valeur saisie dans le champ de saisie de type "time"

		// Obtient la date actuelle
		const currentDate = new Date();

		// Sépare l'heure saisie en heures et minutes
		const [hours, minutes] = timeValue.split(":");

		// Définit la date actuelle avec l'heure saisie
		currentDate.setHours(Number(hours), Number(minutes), 0, 0);

		// Formate manuellement la date au format "YYYY-MM-DD HH:mm:ss"
		const formattedDateTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")} ${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate
			.getSeconds()
			.toString()
			.padStart(2, "0")}`;

		// Met à jour l'état avec la nouvelle date complète
		setAppointmentData((prev) => ({
			...prev,
			starting_at: formattedDateTime, // Met à jour starting_at avec la nouvelle date formatée
		}));
	};

	const handleSubmit = () => {
		createAppointments(appointmentData);
	};

	useEffect(() => {
		const fetchPros = async () => {
			setPros(await getAllPro());
		};
		fetchPros().catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUserData();
			setUser(data);
		};
		fetchUser().catch((e) => console.log(e));
	}, []);

	return (
		<>
			<div className={`lg:ps-10 flex gap-5 `}>
				<ResizablePanelGroup direction="horizontal">
					{/* Left part (all pros section) */}
					<ResizablePanel defaultSize={65} maxSize={80} minSize={60}>
						<div className="space-y-5 p-5 ">
							{user?.usertype === "Professionnel" && (
								<Alert variant={"warning"}>
									<AlertTriangle className="h-4 w-4" />
									<AlertTitle>Attention</AlertTitle>
									<AlertDescription>Sur cette version, les professionnels ne peuvent pas prendre de rendez-vous. </AlertDescription>
								</Alert>
							)}
							<Input value={searchResults} placeholder={"Recherchez un professionnel, une profession, une adresse..."} className="relative z-[900] py-6 " onChange={(e) => setSearchResults(e.target.value)} />
							<div className="flex flex-col">
								<div className="grid grid-cols-1 xl:grid-cols-2 gap-3 w-auto">
									{pros &&
										prosSliced.map((pro, key) => (
											<>
												{!(user?.usertype === "Professionnel" && user.id === pro.id) && (
													<Card className={`min-h-40 overflow-x-auto whitespace-nowrap hover:border-primary/50 flex flex-col md:flex-row items-center py-3`} key={key}>
														{/* profile picture of pro */}
														<CardHeader className=" p-2">
															<Avatar className="flex justify-center items-center w-24 h-24 lg:w-30 lg:h-30 bg-gray/5 border border-primary/20 rounded-full ">
																<AvatarImage src={pro.profilePicture} className="rounded-full" />
																<AvatarFallback className="text-3xl text-dark-title font-semibold selection:bg-none hover:cursor-default selection:cursor-default">
																	{pro.firstname[0].toUpperCase()}
																	{pro.lastname[0].toUpperCase()}
																</AvatarFallback>
															</Avatar>
														</CardHeader>

														<CardContent>
															<div className="flex flex-col gap-3">
																<div>
																	<div className="flex gap-1.5">
																		<p className="text-primary font-semibold">{pro.firstname}</p>
																		<p className="text-primary font-semibold">{pro.lastname.toUpperCase()}</p>
																	</div>
																	<div>
																		<p className="text-secondary text-lg font-semibold">{pro.speciality ? pro.speciality : "Non renseigne"}</p>
																	</div>
																</div>
																<div>
																	{pro.city && pro.country && (
																		<div className="flex items-center gap-1 text-dark-title text-sm">
																			<HiMapPin />
																			<span className="font-semibold">
																				{pro.city}, {pro.country}
																			</span>
																		</div>
																	)}
																</div>
																<div className="flex gap-2">
																	<Button
																		className="text-xs"
																		size={"sm"}
																		disabled={user?.usertype === "Professionnel"}
																		onClick={() => {
																			handleSetAppointment(pro, pro.id);
																			setStepAppointment(true);
																			setViewSidePro(true);
																		}}>
																		Prendre un rendez-vous
																	</Button>
																	<Button
																		className="text-xs"
																		size={"sm"}
																		variant={"outline"}
																		onClick={() => {
																			handleSetAppointment(pro, pro.id);
																			setStepAppointment(false);
																			setSeePro({ pro_id: pro.id, proSelected: true });
																			setViewSidePro(true);
																		}}>
																		Voir
																	</Button>
																</div>
															</div>
														</CardContent>
													</Card>
												)}
											</>
										))}
								</div>
								{/* Pagination */}
								<div className="w-full flex justify-center pb-10">
									<Pagination className="lg:static mt-10" theme={customPagination} currentPage={currentPage} totalPages={Math.ceil(prosResult.length / itemsPerPage)} onPageChange={onPageChange} showIcons></Pagination>
								</div>
							</div>
						</div>
					</ResizablePanel>

					{/* Handle */}
					<ResizableHandle withHandle />

					{/* Right part (professionnel infos and appointment section) */}
					<ResizablePanel defaultSize={35} maxSize={40} minSize={25} className={`${viewSidePro ? "fixed lg:relative max-lg:z-[999] max-lg:min-w-[93%] bg-white " : "hidden lg:block"}`}>
						<div className="relative right-0 transition-all min-h-screen ">
							{seePro.proSelected ? (
								<div>
									{pros.map((pro, key) => (
										<>
											{pro.id === seePro.pro_id && (
												<>
													{!stepAppointment && (
														<div className={`flex flex-col justify-center animate-fade-left `} key={key}>
															<Button variant={"outline"} className="block absolute top-5 right-5 lg:hidden" onClick={() => setViewSidePro(false)}>
																<X />
															</Button>
															{/* head (names and picture) */}
															<div className="flex flex-col justify-center items-center text-center w-full p-6 gap-6 border-b border-primary/20">
																<Avatar className="flex justify-center items-center w-32 h-32 lg:w-30 lg:h-30 bg-gray/5 border border-primary/20 rounded-full ">
																	<AvatarImage src={pro.profilePicture} className="rounded-full" />
																	<AvatarFallback className="text-4xl text-primary font-semibold selection:bg-none hover:cursor-default selection:cursor-default">
																		{pro.firstname[0].toUpperCase()}
																		{pro.lastname[0].toUpperCase()}
																	</AvatarFallback>
																</Avatar>
																<div className="space-y-2">
																	<div className="text-primary">
																		<h1 className="text-2xl font-bold">{pro.lastname.toUpperCase()}</h1>
																		<h1 className="text-2xl">{pro.firstname}</h1>
																	</div>

																	{/* If speciality exist */}
																	{pro.speciality && <p className="text-xl text-secondary font-semibold">{pro.speciality}</p>}
																	<div>
																		<Button
																			variant={"outline"}
																			className="text-primary"
																			disabled={user?.usertype === "Professionnel"}
																			onClick={() => {
																				setSeePro((prev) => ({ ...prev, proSelected: true }));
																				setStepAppointment(true);
																			}}>
																			Prendre rendez-vous
																		</Button>
																	</div>
																</div>
															</div>

															{/* body (pro informations) */}
															<div className="self-center pt-10 w-full px-4">
																<Card className="border-primary/20 text-center ">
																	<CardHeader className="border-b border-muted py-3">
																		<div className="flex items-center justify-center gap-1 text-dark-title">
																			<HiOutlineIdentification size={24} />
																			<h1 className="text-lg font-bold">Informations</h1>
																		</div>
																	</CardHeader>
																	<CardContent className="space-y-10 p-6">
																		{/* Details info */}
																		<div className="flex flex-col items-center gap-1 text-primary">
																			<div className="flex items-center gap-2">
																				<HiInformationCircle />
																				<p className="font-semibold">Détails</p>
																			</div>
																			<div>
																				<p className="text-primary/60">{pro.description ? pro.description : "Aucune description pour ce professionnel"}</p>
																			</div>
																		</div>

																		{/* Mail info */}
																		<div className="flex flex-col items-center gap-1 text-primary">
																			<div className="flex items-center gap-2">
																				<HiMail />
																				<p className="font-semibold">Adresse mail</p>
																			</div>
																			<div>
																				<p className="text-primary/60">{pro.email}</p>
																			</div>
																		</div>

																		{/* Phone info */}
																		<div className="flex flex-col items-center gap-1 text-primary">
																			<div className="flex items-center gap-2">
																				<HiPhone />
																				<p className="font-semibold">Téléphone</p>
																			</div>
																			<div>
																				<p className="text-primary/60">{pro.phone ? pro.phone : "Le numéro de téléphone n'a pas encore été renseigné par le professionnel"}</p>
																			</div>
																		</div>

																		{/* Office info */}
																		<div className="flex flex-col items-center gap-1 text-primary">
																			<div className="flex items-center gap-2">
																				<HiOfficeBuilding />
																				<p className="font-semibold">Cabinet</p>
																			</div>
																			<div>
																				{pro.officeName || pro.openingHours ? (
																					<>
																						<p className="text-primary/60">{pro.officeName}</p>
																						<p className="text-primary/60">{pro.openingHours}</p>
																					</>
																				) : (
																					<p className="text-primary/60">Ce professionnel ne possède pas de cabinet</p>
																				)}
																			</div>
																		</div>

																		{/* Adress info */}
																		<div className="flex flex-col items-center gap-1 text-primary">
																			<div className="flex items-center gap-2">
																				<HiMapPin />
																				<p className="font-semibold">Adresse du cabinet</p>
																			</div>
																			<div>
																				<p className="text-primary/60">{pro.adress ? pro.adress : "Ce professionnel ne possède pas de cabinet"}</p>
																				{pro.adress && (
																					<p className="text-primary/60">
																						{pro.zip} {pro.city}, {pro.country}
																					</p>
																				)}
																			</div>
																		</div>
																	</CardContent>
																</Card>
															</div>
														</div>
													)}

													{stepAppointment && (
														<>
															<div className="animate-fade-right ">
																<div className="flex items-center gap-20 p-5">
																	<div className="flex-none w-14">
																		<Button variant={"outline"} className="flex-none" onClick={() => setStepAppointment(false)}>
																			<ArrowLeft />
																			<p className="text-sm">Retour</p>
																		</Button>
																	</div>
																	<div className="grow">
																		<p className="text-primary text-lg font-semibold self-center">
																			Prendre un rendez-vous avec {pro.firstname} {pro.lastname.toUpperCase()}
																		</p>
																	</div>
																	<div className="flex-none w-14"></div>
																</div>

																{/* content (calendar information etc) */}
																<div className="flex flex-col px-6 gap-3">
																	{/* Reason */}
																	<div>
																		<Label>Motif</Label>
																		<Input placeholder="Motif du rendez-vous" onChange={(e) => setAppointmentData((prev) => ({ ...prev, reason: e.target.value }))} />
																	</div>

																	{/* Date */}
																	<div>
																		<Label>Date du rendez-vous</Label>
																		<Input type="date" onChange={(e) => setAppointmentData((prev) => ({ ...prev, date: e.target.value }))} min={moment().format("YYYY-MM-DD")} />
																	</div>

																	{/* Starting at */}
																	<div>
																		<Label>Commencez à</Label>
																		<Input type="time" onChange={handleTimeChange} />
																	</div>

																	{/* Visio ? */}
																	<div>
																		<Label>Type de rendez-vous</Label>
																		<Select value={String(appointmentData.is_visio)} onValueChange={(e) => setAppointmentData((prev) => ({ ...prev, is_visio: Number(e) }))}>
																			<SelectTrigger>
																				<SelectValue />
																			</SelectTrigger>
																			<SelectContent>
																				<SelectItem value="0">Sur place</SelectItem>
																				<SelectItem value="1">Téléconsultation </SelectItem>
																			</SelectContent>
																		</Select>
																		{appointmentData.is_visio === 1 && <p className="text-sm py-1 animate-fade-down duration-300 text-secondary font-medium">Vous recevrez un lien vers le salon de la téléconsultation dans votre boite mail 24h avant le rendez-vous.</p>}
																	</div>

																	<div>
																		<Button onClick={handleSubmit}>Prendre rendez-vous</Button>
																	</div>
																</div>
															</div>
														</>
													)}
												</>
											)}
										</>
									))}
								</div>
							) : (
								<div className="h-screen flex flex-col justify-center items-center px-5 text-center ">
									<div className="rounded-lg border-2 border-dashed border-primary/50  p-8">
										<p className="text-dark-title text-xl font-semibold">Aucun professionnel sélectionné</p>
										<p className="">Selectionnez un professionnel pour voir ses informations ou plannifier un rendez-vous.</p>
									</div>
								</div>
							)}
						</div>
					</ResizablePanel>
				</ResizablePanelGroup>
			</div>
		</>
	);
};
