import { FeaturesCard } from "./FeaturesCard";
import { FeaturesGroupProps } from "@/types/props";

export const FeaturesGroup: React.FC<FeaturesGroupProps> = ({ features }) => {
	return (
		<>
			<div className="text-center md:text-start">
				<p className={`font-bold text-secondary uppercase`}>La solution idéale pour vous</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Connectez vous au juridique </h1>
			</div>
			<div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
				{features.map((feature, key) => (
					<FeaturesCard icon={feature.icon} title={feature.title} desc={feature.desc} key={key} />
				))}
			</div>
		</>
	);
};
