import React, { useEffect, useState } from "react";
import { Card } from "../ui/card";
import { XCircle } from "lucide-react";
import { getPro } from "@/api/prosService";
import moment from "moment";
import { getUserData, getUserById } from "@/api/usersService";
import { Button } from "../ui/button";
import { cancelAppoitments } from "@/api/appointmentsServices";

// TODO: update and create...

export const LastAppointmentCard: React.FC<Appointments> = ({ id, clientId, proId, date, isVisio, reason, startingAt }) => {
	const [pro, setPro] = useState<Pro | null>();
	const [client, setClient] = useState<UserInfo | null>();
	const [user, setUser] = useState<UserInfo>();
	const appointmentDate = moment.utc(date);
	const appointmentStartingAt = moment.utc(startingAt);

	// Fetch user info to determinate his usertype
	useEffect(() => {
		const fetchUser = async () => {
			try {
				const data = await getUserData();
				setUser(data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, []);

	// Fetch user pro
	useEffect(() => {
		const fetchPro = async () => {
			try {
				const data = await getPro(Number(proId));
				setPro(data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchPro();
	}, []);

	// Fetch user client
	useEffect(() => {
		const fetchClient = async () => {
			try {
				const data = await getUserById(Number(clientId));
				setClient(data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchClient();
	}, []);

	// Cancel an appointments
	const handleRemove = () => {
		cancelAppoitments(Number(id));
	};

	return (
		<>
			<Card className=" text-base">
				<div className="flex flex-col gap-3 p-5">
					{/* Card's Header */}
					<div className="flex items-center justify-between w-96">
						<div className="space-y-1">
							<p className="text-xl font-semibold">{reason}</p>
							<p className="text-sm font-normal">{isVisio ? "En téléconsultation" : pro?.adress}</p>
						</div>

						<Button variant={"ghost"} onClick={handleRemove}>
							<XCircle className="text-dark-title w-5 h-5 " />
						</Button>
					</div>
					<div>
						<p className="font-semibold">
							{user?.usertype === "Client" ? (
								<>
									{pro?.firstname} {pro?.lastname} - <span className="text-primary font-bold">{pro?.speciality}</span>
								</>
							) : (
								<>
									{client?.firstname} {client?.lastname}
								</>
							)}
						</p>
					</div>
					<div className="space-y-1">
						<p className="text-2xl text-primary font-semibold">{appointmentStartingAt.format("LT")}</p>
						<p>{appointmentDate.format("dddd DD MMMM")}</p>
						{/* <p>{appointmentDate.fromNow()}</p> */}
					</div>
				</div>
			</Card>
		</>
	);
};
