import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Header } from "@/components/header/Header";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ArrowRight, Check } from "lucide-react";
import { KeyNumbers } from "@/components/landing/KeyNumbers";
import { Partners } from "@/components/landing/Partners";
import { FAQSection } from "@/components/landing/FAQSection";
// import { Security } from "@/components/landing/Security";
import { Contact } from "@/components/landing/Contact";
import { FeaturesProGroup } from "@/components/landing/FeaturesProGroup";
import { PricingGroup } from "@/components/landing/PricingGroup";
import appPreviewPro from "@/components/landing/images/app-preview-pro.svg";
import { specialities } from "@/lib/data";
import { getRegions } from "@/api/regionsServices";
import { Element, Events, scrollSpy } from "react-scroll";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "sonner";
import ReCAPTCHA from "react-google-recaptcha";
import { featuresPro } from "@/lib/data";

export const LandingPro: React.FC = () => {
	const [period, setPeriod] = useState("mois");

	const [regions, setRegions] = useState<Array<{ nom: string; code: number }>>([]);

	// Disabled button when send mail
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Catpcha Value
	const [catpchaVal, setCaptchaVal] = useState<string | null>(null);

	const captchaRef = useRef(null);

	const [helpContact, setHelpContact] = useState({
		firstname: "",
		lastname: "",
		email: "",
		regions: "",
		tel: "",
		speciality: "",
		subject: "",
	});

	const prices = [
		{
			formule: "Débutant",
			desc: "Professionnels souhaitant bénéficier de fonctionnalités supplémentaires",
			price: 9.99,
			period: period,
			features: ["Prise de rendez-vous en ligne", "Paramétrage du compte", "Annuaire juridique", "+ de 100 rendez-vous"],
			best: false,
		},
		{
			formule: "Intermédiaire",
			desc: "Professionnels souhaitant bénéficier de fonctionnalités supplémentaires",
			price: 19.99,
			period: period,
			features: ["Prise de rendez-vous en ligne", "Paramétrage du compte", "Annuaire juridique", "Accès à la téléconsultation", "+ de 100 rendez-vous"],
			best: true,
		},
		{
			formule: "Avancé",
			desc: "Professionnels souhaitant bénéficier de fonctionnalités supplémentaires",
			price: 39.99,
			period: period,
			features: ["Prise de rendez-vous en ligne", "Paramétrage du compte", "Annuaire juridique", "Accès à la téléconsultation", "Statistiques avancées", "+ de 100 rendez-vous"],
			best: false,
		},
	];

	const sentences = ["un meilleur confort de travail.", "une bonne gestion de vos rendez-vous.", "une meilleure visibilité de votre expertise."];
	const [currentSentencesIndex, setCurrentSentencesIndex] = useState(0);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		if (catpchaVal) {
			setIsSubmitting(true);

			// Identification au compte EmailJS
			const publicKey: string = "MjIfD4GxiBI5JkGqA" as const;
			const serviceID: string = "service_svvxweo" as const;
			const templateID: string = "template_l557chr" as const;

			// Parametre pour indiquer les informations du formulaire
			const templateParams = {
				from_name: helpContact.firstname + " " + helpContact.lastname,
				from_email: helpContact.email,
				to_name: "Nadir GHARBI / Kerian DELANCRET",
				from_subject: helpContact.subject,
				speciality: helpContact.speciality,
				regions: helpContact.regions,
				tel: helpContact.tel,
			};

			// Requete qui effectue l'envoie du mail
			emailjs
				.send(serviceID, templateID, templateParams, publicKey)
				.then((response) => {
					toast.success("Votre message a été envoyé avec succès !");
					console.log(response);
					window.location.reload();
				})
				.catch((error) => {
					toast.error("Erreur lors de l'envoi du mail. Source de l'erreur :" + error);
					console.error("Erreur lors de l'envoi du mail", error);
				})
				.finally(() => {
					setIsSubmitting(false); // Remettre le bouton à l'état initial
					setCaptchaVal(null); // Réinitialiser la valeur du captcha
					setHelpContact({ firstname: "", lastname: "", email: "", subject: "", regions: "", speciality: "", tel: "" }); // Réinitialiser les valeurs du formulaire
				});
		} else {
			toast.error("Veuillez valider le captcha avant d'envoyer le message !");
		}
	};

	const validationForm = () => {
		return Boolean(helpContact.email && helpContact.firstname && helpContact.lastname && helpContact.regions && helpContact.subject && helpContact.speciality);
	};

	useEffect(() => {
		const fetchRegions = async () => {
			const data = await getRegions();
			setRegions(data);
		};
		fetchRegions();
	}, []);

	// For scroll to section
	useEffect(() => {
		// Registering the 'begin' event and logging it to the console when triggered.
		Events.scrollEvent.register("begin", () => {});

		// Registering the 'end' event and logging it to the console when triggered.
		Events.scrollEvent.register("end", () => {});

		// Updating scrollSpy when the component mounts.
		scrollSpy.update();

		// Returning a cleanup function to remove the registered events when the component unmounts.
		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSentencesIndex((prevIndex) => (prevIndex + 1) % sentences.length);
		}, 2500); // Change le mot toutes les 3 secondes

		return () => clearInterval(interval);
	}, []); // Effect se déclenche une seule fois après le montage du composant

	return (
		<>
			{/*===== Section 0 : Hero section + Header =====*/}
			<div>
				<Toaster richColors position="top-center" />
				<div className="absolute bg-primary w-full h-[850px] "></div>
				<Header view="pro" />

				<Element name="contact-pro" id="contact-pro"></Element>

				<div className="flex flex-col lg:flex-row items-center lg:items-start gap-10 px-4 md:px-4 pt-32 pb-8 md:pb-12 xl:pb-0 overflow-hidden relative transition-all z-[99] md:overflow-visible lg:justify-evenly">
					{/* left */}
					<div className="flex flex-col gap-10 p-4 lg:px-10 w-full md:lg:w-6/12 min-xl:w-5/12">
						<div className="space-y-1">
							<p className={`font-bold text-gray uppercase`}>L'outil juridique N° 1</p>
							<h1 className="text-4xl xl:text-5xl font-bold text-light">LegalConnect Pro pour</h1>
							{sentences.map((sentence, index) => (
								<h1 className={`text-4xl xl:text-5xl font-bold text-secondary ${currentSentencesIndex === index ? "animate-slide-down block" : "hidden"}`}>{sentence}</h1>
							))}
						</div>
						{/* span change (effect) */}
						<div className="font-light xl:text-lg text-muted">
							<p className="xl:text-lg w-full mb-3">LegalConnect Pro c’est :</p>
							<ul>
								<li className="flex items-center gap-3 text-muted">
									<Check size={32} strokeWidth={3} className="text-secondary" />
									Un confort de travail agréable et une interface épurée
								</li>
								<li className="flex items-center gap-3 text-muted">
									<Check size={32} strokeWidth={3} className="text-secondary" />
									Une excellente gestion de vos dossiers, rendez-vous et bien plus
								</li>
								<li className="flex items-center gap-3 text-muted">
									<Check size={32} strokeWidth={3} className="text-secondary" />
									Une relation et une communication entre professionnels et clients renfoncée
								</li>
								<li className="flex items-center gap-3 text-muted">
									<Check size={32} strokeWidth={3} className="text-secondary" />
									Un confort de travail agréable et une interface épurée
								</li>
							</ul>
						</div>

						<form onSubmit={handleSubmit} className="flex flex-col border-2 border-none p-5 gap-4 rounded-lg shadow-md relative z-90 bg-light" id="contact-pro">
							<div>
								<h1 className="text-xl font-semibold text-dark-title my-1">Pour toute demande, contactez notre services d’assistante</h1>
							</div>
							{/* Firstname & Lastname*/}
							<div className="flex gap-3">
								<Input placeholder="Nom" type="text" onChange={(e) => setHelpContact((prev) => ({ ...prev, firstname: e.target.value }))} />
								<Input placeholder="Prenom" type="text" onChange={(e) => setHelpContact((prev) => ({ ...prev, lastname: e.target.value }))} />
							</div>

							{/* Email */}
							<div>
								<Input placeholder="Email" type="email" onChange={(e) => setHelpContact((prev) => ({ ...prev, email: e.target.value }))} />
							</div>

							{/* Region */}
							<div className="flex gap-3">
								<Select value={helpContact.regions} onValueChange={(e) => setHelpContact((prev) => ({ ...prev, regions: e }))}>
									<SelectTrigger className={`w-full `}>
										<SelectValue placeholder="Ville ou Région" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{regions.map((region) => (
												<SelectItem value={region.nom}>{region.nom}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
								<Input placeholder="Numéro de téléphone" type="tel" onChange={(e) => setHelpContact((prev) => ({ ...prev, tel: e.target.value }))} />
							</div>

							{/* specialities */}
							<div>
								<Select value={helpContact.speciality} onValueChange={(e) => setHelpContact((prev) => ({ ...prev, speciality: e }))}>
									<SelectTrigger className={`w-full`}>
										<SelectValue placeholder="Profession" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{specialities.map((speciality) => (
												<SelectItem value={speciality}>{speciality}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>

							{/* subject */}
							<div>
								<Select value={helpContact.subject} onValueChange={(e) => setHelpContact((prev) => ({ ...prev, subject: e }))}>
									<SelectTrigger className={`w-full `}>
										<SelectValue placeholder="Objet de votre demande" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											<SelectItem value={"Je souhaite me renseigner sur l'outil"}>Je souhaite me renseigner sur l'outil</SelectItem>
											<SelectItem value={"Je possède un cabinet et je souhaite m'inscrire"}>Je possède un cabinet et je souhaite m'inscrire</SelectItem>
											<SelectItem value={"Je n'ai pas de demande particulière"}>Je n'ai pas de demande particulière</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<div>
								{validationForm() && (
									<div className={`mb-3`}>
										<ReCAPTCHA sitekey="6LdeCtYpAAAAAEMA-1LZzX-fX4KDHGzmIPrYiD1Y" onChange={(value: any) => setCaptchaVal(value)} ref={captchaRef} onExpired={() => console.log("expire")} />
									</div>
								)}
								<Button className="w-full" disabled={isSubmitting}>
									{isSubmitting ? "Envoi en cours" : "Envoyer"}
								</Button>
							</div>
						</form>
					</div>

					{/* right */}
					<div>
						<img src={appPreviewPro} alt="appPreviewPro" className="max-lg:w-[600px] xl:w-[700px] lg:translate-y-36" />
					</div>
				</div>
			</div>

			{/*===== Section 5 : Description de l'application =====*/}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<p className="block md:hidden text-dark-title text-xl font-semibold text-center">LegalConnect c'est : </p>
				<KeyNumbers />
			</div>

			{/*====== Section 1 : Liste of profession  ======*/}
			<div className="py-10 w-5/6 md:w-3/4 xl:w-2/3 mx-auto max-md:text-center space-y-10 transition-all">
				<div>
					<p className={`font-bold text-secondary uppercase`}>spécialement pour vous</p>
					<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Un outil conçue pour les professionnels </h1>
				</div>
				<div className="flex justify-between">
					<ul className="space-y-3">
						{specialities.map((speciality, key) => (
							<li className="flex items-center gap-3 text-primary font-semibold" key={key}>
								<ArrowRight size={32} className="text-primary" />
								{speciality}
							</li>
						))}
					</ul>
				</div>
				<div className="flex flex-col gap-3 ">
					<NavLink className={`font-bold border-2 py-2 px-12 rounded-lg transition-all bg-secondary hover:bg-secondary/90 border-secondary text-center text-light md:self-start`} to={"/pro"}>
						Je m'inscris
					</NavLink>
					<p>
						Votre profession n'apparait ici ? Contactez le support de communicartion pour en savoir plus : <span className="font-semibold">contact@legalconnect.fr</span>{" "}
					</p>
				</div>
			</div>

			<div className="flex justify-center ">
				<Separator className="bg-primary/20 w-9/12" />
			</div>

			{/*===== Section 3 : Réseau de partenaires =====*/}
			<div className="py-10 w-5/6 md:w-3/4 xl:w-2/3 mx-auto transition-all">
				<Partners />
			</div>

			{/* <Partners /> */}

			<Element name="our-tool">
				{/*===== Section 4 : Etapes de prise de rendez vous + Call to action inscription =====*/}
				<div className="py-40 px-6 xl:px-20 space-y-10 transition-all flex flex-col justify-center items-center bg-primary">
					<FeaturesProGroup features={featuresPro} />
				</div>
			</Element>

			{/*===== Section 4 : Protection de vos données =====*/}
			{/* <div className="py-20 mx-auto space-y-10 transition-all">
				<Security />
			</div> */}

			{/* ===== Section 4 : Tarifs ===== */}
			<Element name="pricing" id="pricing">
				<div className="py-20 px-4 md:px-0 xl:px-12 mx-auto space-y-10 transition-all" id="pricing">
					<PricingGroup prices={prices} period={period} setPeriod={setPeriod} />
				</div>
			</Element>

			{/*===== Section 6 : FAQ =====*/}
			{/* FAQ */}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<FAQSection />
			</div>

			<Separator className="border border-dashed border-primary/50 w-full" />

			{/* ===== Section 7 : contact assistance =====*/}
			<div className="py-20 w-5/6 md:w-3/4 xl:w-2/3 mx-auto space-y-10 transition-all">
				<Contact />
			</div>

			{/*===== Section 7 : Footer =====*/}
			{/* <Footer /> */}
		</>
	);
};
