import { CustomFlowbiteTheme } from "flowbite-react";

// ================ THEMES ================
export const customPagination: CustomFlowbiteTheme["pagination"] = {
	base: "",
	layout: {
		table: {
			base: "text-lg text-primary dark:text-light",
			span: "font-semibold text-primary dark:text-white",
		},
	},
	pages: {
		base: "xs:mt-0 mt-2 inline-flex gap-2 items-center -space-x-px ",
		showIcon: "inline-flex",
		previous: {
			base: "transition-all ml-0 rounded-lg bg-transparent p-3 leading-tight text-primary/80 enabled:hover:bg-primary/5 enabled:hover:primary ",
			icon: "h-5 w-5",
		},
		next: {
			base: "transition-all ml-0 rounded-lg bg-transparent p-3 leading-tight text-primary/80 enabled:hover:bg-primary/5 enabled:hover:primary ",
			icon: "h-5 w-5",
		},
		selector: {
			base: "transition-all rounded-lg w-12 bg-transparent p-3 leading-tight text-primary enabled:hover:bg-primary/5 enabled:hover:text-primary ",
			active: "transition-all text-light bg-primary hover:!text-light hover:!bg-primary/95",
			disabled: "transition-all cursor-not-allowed opacity-50",
		},
	},
};

export const customDatePicker: CustomFlowbiteTheme["datepicker"] = {
	root: {
		base: "relative",
	},
	popup: {
		root: {
			base: "absolute top-10 z-50 block pt-2",
			inline: "relative top-0 z-auto",
			inner: "inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-dark-title",
		},
		header: {
			base: "",
			title: "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
			selectors: {
				base: "mb-2 flex justify-between",
				button: {
					base: "rounded-lg bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary dark:bg-secondary dark:text-white dark:hover:bg-secondary",
					prev: "",
					next: "",
					view: "",
				},
			},
		},
		view: {
			base: "p-1",
		},
		footer: {
			base: "mt-2 flex space-x-2",
			button: {
				base: "w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-primary",
				today: "bg-primary text-white hover:bg-primary dark:bg-primary dark:hover:bg-primary",
				clear: "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600",
			},
		},
	},
	views: {
		days: {
			header: {
				base: "mb-1 grid grid-cols-7",
				title: "h-6 text-center text-sm font-medium leading-6 text-primary/40 dark:text-gray-400",
			},
			items: {
				base: "grid w-64 grid-cols-7",
				item: {
					base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 ",
					selected: "bg-primary text-white hover:bg-primary",
					disabled: "text-primary/40",
				},
			},
		},
		months: {
			items: {
				base: "grid w-64 grid-cols-4",
				item: {
					base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
					selected: "bg-primary text-white hover:bg-primary",
					disabled: "text-primary/40",
				},
			},
		},
		years: {
			items: {
				base: "grid w-64 grid-cols-4",
				item: {
					base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
					selected: "bg-primary text-white hover:bg-primary",
					disabled: "text-primary/40",
				},
			},
		},
		decades: {
			items: {
				base: "grid w-64 grid-cols-4",
				item: {
					base: "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
					selected: "bg-primary text-white hover:bg-primary",
					disabled: "text-primary/40",
				},
			},
		},
	},
};
