import React from "react";
import maestris from "@/components/landing/images/maestris.svg";
import pepite from "@/components/landing/images/pepite.svg";
import esgi from "@/components/landing/images/esgi.svg";
import skolae from "@/components/landing/images/skolae.svg";

export const Partners: React.FC = () => {
	const partners = [maestris, pepite, esgi, skolae];
	return (
		<>
			<div className="text-center mb-20">
				<p className={`font-bold text-secondary uppercase`}>Ils nous font confiance</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Nos partenaires </h1>
			</div>
			<div className="grid grid-cols-2 lg:grid-cols-4 justify-items-center py-3 gap-10">
				{partners.map((partner, key) => (
					<img key={key} src={partner} alt={partner.split("/").pop()} className="grayscale hover:grayscale-0 transition-all w-7/12 h-16" />
				))}
			</div>
		</>
	);
};
