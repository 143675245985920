import { FeaturesProCard } from "./FeaturesProCard";
import { FeaturesGroupProps } from "@/types/props";
import { NavLink } from "react-router-dom";

export const FeaturesProGroup: React.FC<FeaturesGroupProps> = ({ features }) => {
	return (
		<>
			<div className="text-center">
				<p className={`font-bold text-gray uppercase`}>Pratique et facile</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-light">Pourquoi choisir LegalConnect Pro ?</h1>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
				{features.map((feature, key) => (
					<FeaturesProCard icon={feature.icon} title={feature.title} desc={feature.desc} key={key} />
				))}
			</div>
			{/* CTA */}
			<NavLink className={`font-bold border-2 py-2 px-12 rounded-lg transition-all bg-light hover:bg-light/90 border-light w-full md:w-fit text-center `} to={"/register"}>
				Être contacté
			</NavLink>
		</>
	);
};
