import { getPro } from "@/api/prosService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../header/Header";
import { HiMapPin, HiPhone } from "react-icons/hi2";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { HiInformationCircle, HiMail, HiOfficeBuilding, HiOutlineIdentification } from "react-icons/hi";
import { isUserAuthentificated } from "@/lib/data";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";

export const ProPage = () => {
	const [proData, setProData] = useState<Pro>();
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchPro = async () => {
			try {
				setProData(await getPro(Number(id)));
			} catch (error) {
				console.log(error);
			}
		};
		fetchPro();
	}, [id]);

	return (
		<>
			<Header view="client" />
			{proData ? (
				<>
					<div>
						<div className="flex justify-center bg-primary px-24 pt-40 pb-10">
							<div className="text-white flex items-center gap-8">
								<Avatar className="flex justify-center items-center w-32 h-32 lg:w-30 lg:h-30 bg-gray/5 border border-light/30 rounded-full ">
									<AvatarImage src={proData.profilePicture} className="rounded-full" />
									<AvatarFallback className="text-4xl text-light font-semibold selection:bg-none hover:cursor-default selection:cursor-default">
										{proData.firstname[0].toUpperCase()}
										{proData.lastname[0].toUpperCase()}
									</AvatarFallback>
								</Avatar>
								<div className="space-y-2">
									<div>
										<h1 className="text-3xl font-bold">{proData.lastname.toUpperCase()}</h1>
										<h1 className="text-3xl">{proData.firstname}</h1>
									</div>

									{/* If speciality exist */}
									{proData.speciality && <p className="text-xl text-secondary font-semibold">{proData.speciality}</p>}
									<div>
										<Button variant={"outline"} className="text-primary" onClick={() => (isUserAuthentificated() ? navigate("/dashboard") : navigate("/login"))}>
											Prendre RDV
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="min-h-full flex flex-row justify-center items-center px-5 lg:px-24 py-20 gap-5 ">
							{/* proData's informations */}
							<Card className="border-muted text-center w-[40rem]">
								<CardHeader className="border-b border-muted py-2">
									<div className="flex items-center justify-center gap-1 text-dark-title">
										<HiOutlineIdentification size={24} />
										<h1 className="text-lg font-bold">Informations</h1>
									</div>
								</CardHeader>
								<CardContent className="space-y-10 p-6">
									{/* Details info */}
									<div className="flex flex-col items-center gap-1 text-primary">
										<div className="flex items-center gap-2">
											<HiInformationCircle />
											<p className="font-semibold">Détails</p>
										</div>
										<div>
											<p className="text-primary/60">{proData.description ? proData.description : "Aucune description pour ce professionnel"}</p>
										</div>
									</div>

									{/* Mail info */}
									<div className="flex flex-col items-center gap-1 text-primary">
										<div className="flex items-center gap-2">
											<HiMail />
											<p className="font-semibold">Adresse mail</p>
										</div>
										<div>
											<p className="text-primary/60">{proData.email}</p>
										</div>
									</div>

									{/* Phone info */}
									<div className="flex flex-col items-center gap-1 text-primary">
										<div className="flex items-center gap-2">
											<HiPhone />
											<p className="font-semibold">Téléphone</p>
										</div>
										<div>
											<p className="text-primary/60">{proData.phone ? proData.phone : "Le numéro de téléphone n'a pas encore été renseigné par le professionnel"}</p>
										</div>
									</div>

									{/* Office info */}
									<div className="flex flex-col items-center gap-1 text-primary">
										<div className="flex items-center gap-2">
											<HiOfficeBuilding />
											<p className="font-semibold">Cabinet</p>
										</div>
										<div>
											{proData.officeName || proData.openingHours ? (
												<>
													<p className="text-primary/60">{proData.officeName}</p>
													<p className="text-primary/60">{proData.openingHours}</p>
												</>
											) : (
												<p className="text-primary/60">Ce professionnel ne possède pas de cabinet</p>
											)}
										</div>
									</div>

									{/* Adress info */}
									<div className="flex flex-col items-center gap-1 text-primary">
										<div className="flex items-center gap-2">
											<HiMapPin />
											<p className="font-semibold">Adresse du cabinet</p>
										</div>
										<div>
											<p className="text-primary/60">{proData.adress ? proData.adress : "Ce professionnel ne possède pas de cabinet"}</p>
											{proData.adress && (
												<p className="text-primary/60">
													{proData.zip} {proData.city}, {proData.country}
												</p>
											)}
										</div>
									</div>
								</CardContent>
							</Card>
							{/* proData's Cordinate */}
						</div>
					</div>
				</>
			) : (
				<p>Aucun pro n'a ete trouvé</p>
			)}
		</>
	);
};
