import React from "react";
import { Button } from "../ui/button";
import { FaApple, FaGooglePlay, FaInstagram, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import logoWhite from "@/assets/lc-logo-light.png";
import { NavLink, useLocation } from "react-router-dom";
import { Separator } from "../ui/separator";

export const Footer: React.FC = () => {
	const currentYear = new Date().getFullYear();
	const location = useLocation();

	if (location.pathname == "/login" || location.pathname == "/register") {
		return null
	}

	return (
		<div className="static bottom-0 bg-primary text-light">
			<div className="flex flex-col md:flex-row justify-center md:justify-between py-10 px-4 md:px-12 gap-12">
				{/* left */}
				<div className="grid grid-cols-2 md:flex md:flex-col gap-y-4 gap-x-12 lg:items-start">
					<div className="flex flex-col gap-3">
						<img src={logoWhite} alt="logoWhite" width={150} />
						<small className="font-semibold w-5/6">Copyright &copy; {currentYear} LegalConnect by Konect, tous droits réservés.</small>
					</div>
					{/* Button download application */}
				</div>

				{/* right */}
				<div className="flex flex-col gap-4 items-center justify-start md:items-end lg:flex-col">
					{/* Social */}
					<div className="flex gap-1">
						<NavLink to={"/"} className="opacity-30 hover:opacity-100">
							{/* insert link to social network */}
							<FaLinkedin size={24} />
						</NavLink>
						<NavLink to={"/"} className="opacity-30 hover:opacity-100">
							{/* insert link to social network */}
							<FaInstagram size={24} />
						</NavLink>
						<NavLink to={"/"} className="opacity-30 hover:opacity-100">
							{/* insert link to social network */}
							<FaXTwitter size={24} />
						</NavLink>
					</div>
					<div className="flex flex-col md:flex-row justify-self-end gap-3">
						{/* Apple */}
						<Button className="p-5 w-48 h-12 space-x-6 bg-dark-title hover:bg-dark-title/75">
							<FaApple size={40} />
							<p className="text-start text-xs">
								Télécharger sur <span className="font-bold">Apple Store</span>
							</p>
						</Button>
						{/* Android */}
						<Button className="p-5 w-48 h-12 space-x-6 bg-dark-title hover:bg-dark-title/75">
							<FaGooglePlay size={40} />
							<p className="text-start text-xs">
								Télécharger sur <span className="font-bold">Play Store</span>
							</p>
						</Button>
					</div>
					{/* Newsletter */}
					{/* <p className="font-bold">
						Inscrivez vous a notre <span className="text-secondary">newsletter</span>{" "}
					</p>
					<div className="flex flex-row items-center border-2 bg-light border-none py-2 px-2 ps-4 gap-1 rounded-lg w-full">
						<FaEnvelope size={42} color="gray" />
						<Input placeholder="Email" className="w-full border-none rounded-none focus-visible:ring-0 focus:ring-offset-0 placeholder:text-gray text-primary" type="email" />
						<Button className="px-8 text-light bg-primary hover:bg-primary/95 font-semibold">S'inscrire</Button>
					</div> */}
				</div>
			</div>
			<Separator className="bg-light/20" />
			{/* CGU and others */}
			<div className="py-5 text-muted text-sm text-center lg:text-start">
				<p className="font-bold pb-3 text-center">Une solution par <strong>Konect</strong></p>
				<ul className="flex flex-col lg:flex-row items-center md:justify-center px-8 gap-1 lg:gap-6">
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Conditions générales d'utilisation</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Conditions d'utilisation du site LegalConnect</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Politique relative à la protection des données personnelles</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Politique en matière de cookies</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Gestion des cookies et consentement</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Règles de référencement</NavLink>
					</li>
					<li className="text-sm hover:underline lg:list-disc lg:self-start">
						<NavLink to={"/"}>Mentions légales</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};
