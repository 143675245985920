import React from "react";
import { Card, CardTitle, CardHeader, CardContent } from "@/components/ui/card";
import { FeatureProps } from "@/types/props";

export const FeaturesProCard: React.FC<FeatureProps> = ({ icon, title, desc }) => {
	return (
		<div className="">
			<Card className="h-full border-muted/50 bg-dark-title/25">
				<CardHeader className="flex flex-col xl:flex-row items-center gap-3">
					{icon}
					<CardTitle className="text-light font-semibold text-center">{title}</CardTitle>
				</CardHeader>
				<CardContent className="">
					<p className="text-light">{desc} </p>
				</CardContent>
			</Card>
		</div>
	);
};
