import { useEffect, useState } from "react";
import { Sidebar } from "../../sidebar/Sidebar";
import { SidebarSettings } from "./SidebarSettings";
import { getUserData, updateUser } from "@/api/usersService";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Edit } from "lucide-react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

export const SettingsUser = () => {
	const [user, setUser] = useState<UserInfo>();

	const [userUpdate, setUserUpdate] = useState<UserUpdate>({
		firstname: "",
		lastname: "",
		speciality: "",
		phone: "",
		description: "",
		city: "",
		country: "",
		zip: 0,
	});

	const handleUpdateUser = () => {
		updateUser(userUpdate);
	};

	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUserData();
			setUser(data);
			setUserUpdate({
				firstname: data?.firstname || "",
				lastname: data?.lastname || "",
				speciality: data?.speciality || "",
				phone: data?.phone || "",
				description: data?.description || "",
				city: data?.city || "",
				country: data?.country || "",
				zip: data?.zip || 0,
			});
		};
		fetchUser();
	}, []);

	return (
		<>
			<div>
				<Sidebar />
				<div className="flex flex-row min-w-full">
					<SidebarSettings />
					<div className="flex flex-col w-full ps-20 lg:ps-[530px] max-lg:ms-12 pe-10 lg:pe-20 py-[30px] gap-6">
						<p className="text-2xl font-semibold">Informations du compte</p>
						<div className="grid items-center gap-4">
							<div>
								<Label>Prenom</Label>
								<Input value={userUpdate.firstname} onChange={(e) => setUserUpdate((prev) => ({ ...prev, firstname: e.target.value }))} />
							</div>

							<div>
								<Label>Nom</Label>
								<Input value={userUpdate.lastname} onChange={(e) => setUserUpdate((prev) => ({ ...prev, lastname: e.target.value }))} />
							</div>

							<div>
								<Label>Email</Label>
								<Input value={user?.email} disabled type="email" />
							</div>

							<div>
								<Label>Telephone</Label>
								<Input value={userUpdate.phone} onChange={(e) => setUserUpdate((prev) => ({ ...prev, phone: e.target.value }))} type="phone" />
							</div>

							{user?.usertype === "Professionnel" && (
								<>
									<div>
										<Label>Profession</Label>
										<Select value={userUpdate.speciality} onValueChange={(e) => setUserUpdate((prev) => ({ ...prev, speciality: e }))}>
											<SelectTrigger className={`w-full`}>
												<SelectValue placeholder="Indiquez votre profession" />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup>
													<SelectItem value={"Avocat"}>Avocat(e)</SelectItem>
													<SelectItem value={"Juge"}>Juge</SelectItem>
													<SelectItem value={"Notaire"}>Notaire</SelectItem>
													<SelectItem value={"Huissier de justice"}>Huissier de justice</SelectItem>
													<SelectItem value={"Juriste"}>Juriste</SelectItem>
												</SelectGroup>
											</SelectContent>
										</Select>
									</div>

									<div>
										<Label>Description de votre profil</Label>
										<Textarea value={userUpdate.description} onChange={(e) => setUserUpdate((prev) => ({ ...prev, description: e.target.value }))} maxLength={300} className="max-h-44" />
									</div>
								</>
							)}

							<div>
								<Label>Ville</Label>
								<Input value={userUpdate.city} onChange={(e) => setUserUpdate((prev) => ({ ...prev, city: e.target.value }))} />
							</div>

							<div>
								<Label>Code Postal / ZIP</Label>
								<Input value={userUpdate.zip} onChange={(e) => setUserUpdate((prev) => ({ ...prev, zip: Number(e.target.value) }))} />
							</div>

							<div>
								<Label>Pays</Label>
								<Select value={userUpdate.country} onValueChange={(e) => setUserUpdate((prev) => ({ ...prev, country: e }))}>
									<SelectTrigger className={`w-full`}>
										<SelectValue placeholder="Pays" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											<SelectItem value={"France"}>France</SelectItem>
											<SelectItem value={"Angleterre"}>Angleterre</SelectItem>
											<SelectItem value={"Espagne"}>Espagne</SelectItem>
											<SelectItem value={"Italie"}>Italie</SelectItem>
											<SelectItem value={"Belgique"}>Belgique</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>

							{/* Save Button */}
							<Button className="flex gap-2 items-center w-fit" onClick={handleUpdateUser}>
								<Edit size={18} />
								<span>Enregistrer</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const SettingsOffice = () => {
	const [_, setUser] = useState<UserInfo>();
	const [officeUpdate, setOfficeUpdate] = useState<UserUpdate>({
		adress: "",
		office_name: "",
		opening_hours: "",
	});

	const handleUpdateOffice = () => {
		updateUser(officeUpdate);
	};

	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUserData();
			setUser(data);
			setOfficeUpdate({
				adress: data?.adress || "",
				office_name: data?.officeName || "",
				opening_hours: data?.openingHours || "",
			});
		};
		fetchUser();
	}, []);

	return (
		<>
			<div>
				<Sidebar />
				<SidebarSettings />
				<div className="flex flex-col px-[530px] py-[30px] gap-6">
					<p className="text-2xl font-semibold">Gestion du cabinet </p>
					<div className="grid items-center gap-4">
						<div>
							<Label>Nom du Cabinet</Label>
							<Input value={officeUpdate.office_name} onChange={(e) => setOfficeUpdate((prev) => ({ ...prev, office_name: e.target.value }))} />
						</div>
						<div>
							<Label>Adresse du Cabinet</Label>
							<Input value={officeUpdate.adress} onChange={(e) => setOfficeUpdate((prev) => ({ ...prev, adress: e.target.value }))} />
						</div>
						<div>
							<Label>Horaires d'ouverture</Label>
							<Textarea value={officeUpdate.opening_hours} onChange={(e) => setOfficeUpdate((prev) => ({ ...prev, opening_hours: e.target.value }))} maxLength={200} className="max-h-32" />
						</div>

						{/* Save Button */}
						<Button className="flex gap-2 items-center w-fit" onClick={handleUpdateOffice}>
							<Edit size={18} />
							<span>Enregistrer</span>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export const SettingsAccount = () => {
	return (
		<div>
			<Sidebar />
			<SidebarSettings />
			<div className="flex px-[530px] py-[30px]">
				<p className="text-2xl font-semibold">Paramètres du compte</p>
			</div>
		</div>
	);
};
