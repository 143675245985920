import axios from "axios";

const baseUrl: string = "https://api.legalconnect.nadir-gharbi.fr/api/v1" as const;
// const localUrl = "http://localhost:3333/api/v1" as const;

export const getAllPro = async () => {
	try {
		const reponse = await axios.get(`${baseUrl}/pros`);
		return reponse.data;
	} catch (error) {
		console.log(error);
	}
};

export const getPro = async (id: number) => {
	try {
		const reponse = await axios.get(`${baseUrl}/pros/${id}`);
		return reponse.data;
	} catch (error) {
		console.log(error);
	}
};
