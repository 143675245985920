import React from "react";
import { Card } from "../ui/card";
// import { File } from "lucide-react";
import { BookUser } from "lucide-react";
// import { Plus } from "lucide-react";
// import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
// import { Button } from "../ui/button";
import { NavLink } from "react-router-dom";
// import { AllPros } from "./AllPros";
// import { NewFiles } from "./NewFiles";

export const QuickActions: React.FC<{ role: string }> = ({ role }) => {
	return (
		<div className="flex gap-3 text-center">
			{/* See Legal Directory */}

			{role === "Professionnel" ? (
				<>
					{/* New Meet */}
					<Card className="flex flex-col items-center justify-center gap-5 p-10 w-full md:w-80">
						<BookUser size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
						<p className="font-semibold">Mes rendez-vous</p>
						{/* <Dialog>
              <DialogTrigger className="flex flex-col gap-2 items-center">
                <BookUser size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
                <p className="font-semibold">Mes clients</p>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Mes Rendez-vous</DialogTitle>
                  <DialogDescription>Liste de vos rendez vous</DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog> */}
					</Card>
				</>
			) : (
				<>
					{/* New Meet */}
					<NavLink to={"/directory"}>
						<Card className="flex flex-col items-center justify-center gap-5 p-10 w-full md:w-80">
							<BookUser size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
							<p className="font-semibold">Prendre un rendez-vous</p>
						</Card>
					</NavLink>
					{/* <Dialog>
							<DialogTrigger className="flex flex-col gap-2 items-center">
								<BookUser size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
								<p className="font-semibold">Prendre un rendez-vous</p>
							</DialogTrigger>
							<DialogContent>
								<DialogHeader>
									<DialogTitle>Prendre un Rendez vous</DialogTitle>
									<DialogDescription>
										<p>Cette fonctionnalité n'a pas encore été implémentée</p>
									</DialogDescription>
									</DialogHeader>
									</DialogContent>
								</Dialog> */}
				</>
			)}

			{/* Send a new document */}
			{/* <Card className="flex flex-col items-center justify-center gap-5 p-10 w-full">
        <Dialog>
          <DialogTrigger className="flex flex-col gap-2 items-center">
            <File size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
            <p className="font-semibold">Envoyer un document</p>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Envoyer un document</DialogTitle>
              <DialogDescription>Envoyer un document</DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </Card> */}

			{/* Add a new document */}
			{/* <Card className="flex flex-col items-center justify-center gap-5 p-10 w-full">
        <Dialog>
          <DialogTrigger className="flex flex-col gap-2 items-center">
            <Plus size={40} strokeWidth={1.5} className=" bg-primary text-light border rounded-lg p-2" />
            <p className="font-semibold">Ajouter un document</p>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Ajouter un document</DialogTitle>
              <DialogDescription>
                <NewFiles />
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </Card> */}
		</div>
	);
};
