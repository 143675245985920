import React, { useEffect, useState } from "react";
import { LastAppointmentCard } from "./LastAppointmentCard";
import { getAppointments } from "@/api/appointmentsServices";
import moment from "moment";

export const LastMeetsGroup: React.FC = () => {
	const [appointments, setAppointments] = useState<Appointments[]>();

	useEffect(() => {
		const appointmentsData = async () => {
			const data = await getAppointments();
			setAppointments(data);
		};
		appointmentsData();
	}, []);

	return (
		<>
			<p className="font-bold text-xl text-dark-title">Vos rendez-vous passés</p>
			<div className="flex gap-3 py-3 overflow-x-auto">
				{appointments && appointments.length > 0 ? (
					<>
						{appointments.map((appointment, key) => (
							<>{moment.utc().isSameOrAfter(moment.utc(appointment.date)) && <LastAppointmentCard {...appointment} key={key} />}</>
						))}
					</>
				) : (
					<p className="text-primary p-10 border-dashed border-2 border-secondary rounded-lg">Vous n'avez pas de rendez-vous à venir.</p>
				)}
			</div>
		</>
	);
};
