import React, { useEffect, useState } from "react";
import { Card } from "../ui/card";
import { CircleEllipsis } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { getPro } from "@/api/prosService";
import moment from "moment";
import { getUserById, getUserData } from "@/api/usersService";
import { cancelAppoitments, updateAppointments } from "@/api/appointmentsServices";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { DialogHeader, Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

export const NextAppointmentCard: React.FC<Appointments> = ({ id, clientId, proId, date, isVisio, reason, startingAt }) => {
	const [pro, setPro] = useState<Pro>();
	const [client, setClient] = useState<UserInfo>();
	const [user, setUser] = useState<UserInfo>();
	const appointmentDate = moment.utc(date);
	const appointmentStartingAt = moment.utc(startingAt);
	const [openEdit, setOpenEdit] = useState(false);
	const [openCancel, setOpenCancel] = useState(false);
	const [appointmentUpdate, setAppointmentUpdate] = useState<AppointmentUpdate>({
		reason: reason,
		date: date,
		starting_at: startingAt,
		is_visio: isVisio,
		client_id: clientId,
		pro_id: proId,
	});

	// Fonction pour mettre à jour l'heure de début de rendez-vous
	const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const timeValue = e.target.value; // Obtient la valeur saisie dans le champ de saisie de type "time"

		// Obtient la date actuelle
		const currentDate = new Date();

		// Sépare l'heure saisie en heures et minutes
		const [hours, minutes] = timeValue.split(":");

		// Définit la date actuelle avec l'heure saisie
		currentDate.setHours(Number(hours), Number(minutes), 0, 0);

		// Formate manuellement la date au format "YYYY-MM-DD HH:mm:ss"
		const formattedDateTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")} ${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate
			.getSeconds()
			.toString()
			.padStart(2, "0")}`;

		// Met à jour l'état avec la nouvelle date complète
		setAppointmentUpdate((prev) => ({
			...prev,
			starting_at: formattedDateTime, // Met à jour starting_at avec la nouvelle date formatée
		}));
	};

	// Fetch user info to determinate his usertype
	useEffect(() => {
		const fetchUser = async () => {
			try {
				setUser(await getUserData());
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, []);

	// Fetch user pro
	useEffect(() => {
		const fetchPro = async () => {
			try {
				setPro(await getPro(Number(proId)));
			} catch (error) {
				console.log(error);
			}
		};
		fetchPro();
	}, []);

	// Fetch user client
	useEffect(() => {
		const fetchClient = async () => {
			try {
				setClient(await getUserById(Number(clientId)));
			} catch (error) {
				console.log(error);
			}
		};
		fetchClient();
	}, []);

	// Update appointments
	const handleUpdate = () => {
		updateAppointments(appointmentUpdate, Number(id));
	};

	// Cancel appointments
	const handleCancel = () => {
		cancelAppoitments(Number(id));
	};

	return (
		<>
			<Card className=" text-base">
				<div className="flex flex-col gap-3 p-5">
					{/* Card's Header */}
					<div className="flex items-center justify-between w-96">
						<div className="space-y-1">
							<p className="text-xl font-bold">{reason}</p>
							<p className="text-sm font-normal">{isVisio ? "En téléconsultation" : pro?.adress}</p>
						</div>
						<DropdownMenu>
							<DropdownMenuTrigger>
								<CircleEllipsis className="text-dark-title w-5 h-5" />
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuLabel>Actions</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<DropdownMenuItem onClick={() => setOpenEdit(true)}>Modifier</DropdownMenuItem>
								<DropdownMenuItem onClick={() => setOpenCancel(true)} className="text-red-500">
									Annuler
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>

						<Dialog open={openEdit}>
							<DialogContent>
								<DialogHeader>
									<DialogTitle>Modification du rendez-vous</DialogTitle>
								</DialogHeader>
								<DialogDescription className="space-y-3">
									{/* Reason */}
									<div>
										<Label>Motif</Label>
										<Input value={appointmentUpdate.reason} placeholder="Motif" onChange={(e) => setAppointmentUpdate((prev) => ({ ...prev, reason: e.target.value }))} />
									</div>

									{/* Date */}
									<div>
										<Label>Date du rendez-vous</Label>
										<Input value={appointmentUpdate.date} type="date" onChange={(e) => setAppointmentUpdate((prev) => ({ ...prev, date: e.target.value }))} min={moment().format("YYYY-MM-DD")} />
									</div>

									{/* Starting at */}
									<div>
										<Label>Commencez à</Label>
										<Input type="time" onChange={handleTimeChange} />
									</div>

									{/* Visio */}
									<div>
										<Label>Type de rendez-vous</Label>
										<Select value={appointmentUpdate.is_visio?.toString()} onValueChange={(e) => setAppointmentUpdate((prev) => ({ ...prev, is_visio: Number(e) }))}>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="0">Sur place</SelectItem>
												<SelectItem value="1">Teleconsultation</SelectItem>
											</SelectContent>
										</Select>
									</div>
								</DialogDescription>
								<DialogFooter>
									<Button onClick={handleUpdate}>Confirmer</Button>
									<Button variant={"outline"} onClick={() => setOpenEdit(false)}>
										Annuler
									</Button>
								</DialogFooter>
							</DialogContent>
						</Dialog>

						<AlertDialog open={openCancel}>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Annulation du rendez-vous?</AlertDialogTitle>
									<AlertDialogDescription>Voulez-vous annulez le rendez-vous ?</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel onClick={() => setOpenCancel(false)}>Non</AlertDialogCancel>
									<AlertDialogAction onClick={handleCancel}>Oui</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</div>
					<div>
						{user && user.usertype === "Client" ? (
							<>
								{pro && (
									<p>
										{pro?.firstname} {pro?.lastname} - <span className="text-primary font-bold">{pro?.speciality}</span>
									</p>
								)}
							</>
						) : (
							<>
								{client && (
									<p className="font-semibold">
										{client.firstname} {client?.lastname}
									</p>
								)}
							</>
						)}
					</div>
					<div className="space-y-1">
						<p className="text-2xl text-primary font-semibold">{appointmentStartingAt.format("LT")}</p>
						<p>{appointmentDate.format("dddd DD MMMM")}</p>
					</div>
				</div>
			</Card>
		</>
	);
};
