import React from "react";
import { Card, CardTitle, CardHeader, CardContent } from "@/components/ui/card";
import { FeatureProps } from "@/types/props";

export const FeaturesCard: React.FC<FeatureProps> = ({ icon, title, desc }) => {
	return (
		<div className="text-center">
			<Card className="border-muted">
				<CardHeader>
					{icon}
					{/* <img src={icon} alt={icon} width={64} className="self-center" /> */}
					<CardTitle className="text-primary font-semibold">{title}</CardTitle>
				</CardHeader>
				<CardContent>
					<p className="text-dark-title text-sm">{desc}</p>
				</CardContent>
			</Card>
		</div>
	);
};
