import React, { useEffect, useState } from "react";
import { NextAppointmentCard } from "./NextAppointmentCard";
import { getAppointments } from "@/api/appointmentsServices";
import moment from "moment";

export const NextMeetsGroup: React.FC = () => {
	const [appointments, setAppointments] = useState<Appointments[]>();

	useEffect(() => {
		const appointmentsData = async () => {
			const data = await getAppointments();
			// if (data) {
			setAppointments(data);
			// }
		};
		appointmentsData();
	}, []);

	return (
		<>
			<p className="font-bold text-xl text-dark-title">Vos prochains rendez-vous</p>
			<div className="flex gap-3 py-3 overflow-x-auto">
				{appointments && appointments.length > 0 ? (
					<>
						{appointments.map((appointment, key) => (
							<>{moment().utc().isSameOrBefore(moment.utc(appointment.date)) && <NextAppointmentCard {...appointment} key={key} />}</>
						))}
					</>
				) : (
					<p className="text-primary p-10 border-dashed border-2 border-secondary rounded-lg">Vous n'avez pas de rendez-vous à venir.</p>
				)}
			</div>
		</>
	);
};
