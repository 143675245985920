import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";

const baseUrl = "https://api.legalconnect.nadir-gharbi.fr/api/v1" as const;
// const localUrl = "http://localhost:3333/api/v1" as const;

export const getAppointments = async () => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.get(`${baseUrl}/appointments`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		toast.error("Erreur lors de la récupération des rendez-vous");
		console.error(error);
	}
};

export const createAppointments = async (data: AppointmentsCreate) => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.post(`${baseUrl}/appointments`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		toast.success("Votre rendez-vous a bien été planifié");
		window.location.href = "/dashboard";

		return response.data;
	} catch (error) {
		toast.error("Une erreur est survenue");
		throw new Error(String(error));
	}
};

export const updateAppointments = async (data: AppointmentUpdate, id: number) => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.put(`${baseUrl}/appointments/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		toast.success("Votre rendez-vous a bien été modifié.");
		window.location.reload();

		return response.data;
	} catch (error: any) {
		toast.error("La modification n'a pas été prise en compte. Veuillez remplir tous les champs correctement et respecter le bon format.");
		throw new Error(error);
	}
};

export const cancelAppoitments = async (id: number) => {
	try {
		const response = await axios.delete(`${baseUrl}/appointments/${id}`);
		toast.success("Votre rendez-vous a bien été supprimé");
		window.location.reload();

		return response.data;
	} catch (error: any) {
		toast.error("Une erreur s'est produite lors de la supression");
		throw new Error(error);
	}
};
