import React from "react";
import { GaugeCircle, Hourglass, FolderKanban, ShieldCheck, Handshake, ScanEye, Video, FolderLock, CalendarDays, MessageCircle } from "lucide-react";
import Cookies from "js-cookie";

// ================ AUTH ================
/** @function isUserAuthentificated if user token on cookies, is auth, else, is not auth */
export const isUserAuthentificated = (): boolean => {
	return !!Cookies.get("user_token");
};

// ================ LANDINGS ================
/** @default feature for landing client features */
export const features = [
	{
		icon: React.createElement(Video, { className: "text-primary w-10 h-10 lg:w-auto self-center" }),
		title: "Téléconsultations",
		desc: "Profitez de consultations juridiques en temps réel, que ce soit par chat, appel vidéo ou audio, pour des conseils personnalisés où que vous soyez.",
	},
	{
		icon: React.createElement(FolderLock, { className: "text-primary w-10 h-10 lg:w-auto self-center" }),
		title: "Gestion de vos documents",
		desc: "Centralisez et organisez vos documents juridiques en un seul endroit sécurisé, simplifiant la gestion de l'information.",
	},
	{
		icon: React.createElement(CalendarDays, { className: "text-primary w-10 h-10 lg:w-auto self-center" }),
		title: "Agenda et Planification",
		desc: "Organisez facilement vos consultations grâce à un agenda intuitif, recevez des rappels et suivez vos facturations en toute simplicité.",
	},
	{
		icon: React.createElement(MessageCircle, { className: "text-primary w-10 h-10 lg:w-auto self-center" }),
		title: "Messagerie",
		desc: "Une messagerie sécurisée vous permet d'échanger des informations confidentielles avec vos avocats en toute tranquillité.",
	},
];

/** @default featuresPro for landing pro features */
export const featuresPro = [
	{
		icon: React.createElement(GaugeCircle, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Gain de temps précieux",
		desc: "Fixez des consultations en ligne avec vos clients, optimisant ainsi votre emploi du temps chargé.",
	},
	{
		icon: React.createElement(Hourglass, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Gestion simplifiée",
		desc: "Contrôlez tous les détails de votre cabinet via notre plateforme intuitive, offrant un contrôle total sur votre profil professionnel.",
	},
	{
		icon: React.createElement(FolderKanban, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Confidentialité renforcée",
		desc: "Notre plateforme sécurisée garantit la confidentialité de vos échanges avec les clients, renforçant ainsi la confiance et la relation avec votre clientèle.",
	},
	{
		icon: React.createElement(ShieldCheck, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Grande flexibilite",
		desc: "Planifiez des rendez-vous à tout moment, même en soirée, le week-end ou pendant les vacances, pour renforcer votre disponibilité et votre engagement envers vos clients.",
	},
	{
		icon: React.createElement(Handshake, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Satisfaction clientèle optimale",
		desc: "La téléconsultation fluide et conviviale renforce la fidélité clientèle, favorisant ainsi les recommandations et l'expansion de votre cabinet.",
	},
	{
		icon: React.createElement(ScanEye, { className: "text-light w-8 h-8 lg:w-auto" }),
		title: "Visibilité accrue",
		desc: "Notre annuaire intégré valorise votre expertise, attirant de nouveaux clients et élargissant votre réseau professionnel.",
	},

	// {
	// 	icon: wandSparkles,
	// 	title: "Flexible",
	// 	desc: "Une messagerie sécurisée vous permet d'échanger des informations confidentielles avec vos avocats en toute tranquillité.",
	// },
	// {
	// 	icon: circleGauge,
	// 	title: "Fluide",
	// 	desc: "Une messagerie sécurisée vous permet d'échanger des informations confidentielles avec vos avocats en toute tranquillité.",
	// },
	// {
	// 	icon: lockKeyhole,
	// 	title: "Sécurisé",
	// 	desc: "Une messagerie sécurisée vous permet d'échanger des informations confidentielles avec vos avocats en toute tranquillité.",
	// },
];

/** @default speciality for landing pro contact */
export const specialities: string[] = ["Avocat", "Juge", "Notaire", "Médiateur", "Juriste", "Commissaire de justice"] as const;

// ================ DASHBOARD ================
/** @default roles client | pro */
export const role: string = "client";
