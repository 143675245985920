import React, { useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import emailjs from "@emailjs/browser";
import { toast } from "sonner";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact: React.FC = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Catpcha Value
	const [catpchaVal, setCaptchaVal] = useState<string | null>(null);

	const captchaRef = useRef(null);
	const [contactData, setContactData] = useState({
		email: "",
		subject: "",
		message: "",
	});
	const handleSubmit = (e: any) => {
		e.preventDefault();

		if (catpchaVal) {
			setIsSubmitting(true);

			// Identification au compte EmailJS
			const publicKey: string = "MjIfD4GxiBI5JkGqA" as const;
			const serviceID: string = "service_svvxweo" as const;
			const templateID: string = "template_l557chr" as const;

			// Parametre pour indiquer les informations du formulaire
			const templateParams = {
				from_email: contactData.email,
				from_subject: contactData.subject,
				to_name: "Nadir GHARBI / Kerian DELANCRET",
				subject: contactData.subject,
				message: contactData.message,
			};

			// Requete qui effectue l'envoie du mail
			emailjs
				.send(serviceID, templateID, templateParams, publicKey)
				.then((response) => {
					toast.success("Votre message a été envoyé avec succès !");
					console.log(response);
					window.location.reload();
				})
				.catch((error) => {
					toast.error("Erreur lors de l'envoi du mail. Source de l'erreur :" + error);
					console.error("Erreur lors de l'envoi du mail", error);
				})
				.finally(() => {
					setIsSubmitting(false); // Remettre le bouton à l'état initial
					setCaptchaVal(null); // Réinitialiser la valeur du captcha
					setContactData({ email: "", subject: "", message: "" }); // Réinitialiser les valeurs du formulaire
				});
		} else {
			toast.error("Veuillez valider le captcha avant d'envoyer le message !");
		}
	};

	const validationForm = () => {
		return Boolean(contactData.email && contactData.subject && contactData.message);
	};

	return (
		<>
			<div className="text-center">
				<p className={`font-bold text-secondary uppercase`}>Contactez nous</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Vous avez d’autres questions ? </h1>
				<p className="text-dark-title">Contactez l’assistance pour toutes informations ou demandes.</p>
			</div>
			<div className="flex flex-col items-center">
				<form onSubmit={handleSubmit} className="flex flex-col gap-3 w-full sm:w-11/12 lg:w-5/6 2xl:w-2/5">
					<Input placeholder="Email" type="email" onChange={(e) => setContactData((prev) => ({ ...prev, email: e.target.value }))} />
					<Input placeholder="Objet" type="text" onChange={(e) => setContactData((prev) => ({ ...prev, subject: e.target.value }))} />
					<Textarea placeholder="Message ..." onChange={(e) => setContactData((prev) => ({ ...prev, message: e.target.value }))} />
					<div>
						{validationForm() && (
							<div className={`mb-3`}>
								<ReCAPTCHA sitekey="6LdeCtYpAAAAAEMA-1LZzX-fX4KDHGzmIPrYiD1Y" onChange={(value: any) => setCaptchaVal(value)} ref={captchaRef} onExpired={() => console.log("expire")} />
							</div>
						)}
						<Button className="w-full" disabled={isSubmitting}>
							{isSubmitting ? "Envoi en cours" : "Envoyer"}
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};
