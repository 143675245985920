import { PricingGroupProps } from "@/types/props";
import { PricingCard } from "./PricingCard";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const PricingGroup: React.FC<PricingGroupProps> = ({ prices, period, setPeriod }) => {
	return (
		<>
			<div className="text-center">
				<p className={`font-bold text-secondary uppercase`}>Nos tarifs</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Toutes nos offres pour une expérience exceptionnelle</h1>
			</div>
			<Tabs defaultValue="mois" className="text-center">
				<TabsList>
					<TabsTrigger value="mois" onClick={() => setPeriod("mois")}>
						Mois
					</TabsTrigger>
					<TabsTrigger value="annee" onClick={() => setPeriod("annee")}>
						Annee
					</TabsTrigger>
				</TabsList>
			</Tabs>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-9/12 xl:w-11/12 mx-auto">
				{prices.map((price, key) => (
					<PricingCard formule={price.formule} desc={price.desc} price={period === "annee" ? Math.floor(price.price * 12) : price.price} period={period} features={price.features} best={price.best} key={key} />
				))}
			</div>
		</>
	);
};
