import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import { Login } from "./views/auth/Login";
import { LandingClient } from "./views/landing/LandingClient";
import { Register } from "./views/auth/Register";
import { LandingPro } from "./views/landing/LandingPro";
import { SearchPros } from "./views/search/SearchPros";
import { Dashboard } from "./views/Dashboard";
import { ProPage } from "./components/pro-page/ProPage";
import { isUserAuthentificated } from "./lib/data";
import { SettingsAccount, SettingsOffice, SettingsUser } from "./components/dashboard/settings/Settings";
import { Directory } from "./components/dashboard/Directory";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/",
				element: isUserAuthentificated() ? <Navigate to={"/dashboard"} /> : <LandingClient />,
			},
			{
				path: "/pro",
				element: isUserAuthentificated() ? <Navigate to={"/dashboard"} /> : <LandingPro />,
			},
			{
				path: "/register",
				element: isUserAuthentificated() ? <Navigate to={"/dashboard"} /> : <Register />,
			},
			{
				path: "/login",
				element: isUserAuthentificated() ? <Navigate to={"/dashboard"} /> : <Login />,
			},
			{
				path: "/pros",
				element: <SearchPros />,
			},
			{
				path: `/pros/:id`,
				element: <ProPage />,
			},
			{
				path: `/dashboard`,
				element: isUserAuthentificated() ? <Dashboard /> : <Navigate to={"/login"} />,
			},
			{
				path: `/settings/user`,
				element: isUserAuthentificated() ? <SettingsUser /> : <Navigate to={"/login"} />,
			},
			{
				path: `/settings/account`,
				element: isUserAuthentificated() ? <SettingsAccount /> : <Navigate to={"/login"} />,
			},
			{
				path: `/settings/office`,
				element: isUserAuthentificated() ? <SettingsOffice /> : <Navigate to={"/login"} />,
			},
			{
				path: `/directory`,
				element: isUserAuthentificated() ? <Directory /> : <Navigate to={"/login"} />,
			},
		],
	},
]);
