import React, { useEffect, useState } from "react"; // useState, useEffect
import { Sidebar } from "@/components/sidebar/Sidebar";
import { Header } from "@/components/dashboard/Header";
import { LastMeetsGroup } from "@/components/dashboard/LastAppointmentsGroup";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { NextMeetsGroup } from "@/components/dashboard/NextAppointmentsGroup";
import { QuickActions } from "@/components/dashboard/QuickActions";
import { getUserData } from "@/api/usersService";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Info } from "lucide-react";

export const Dashboard: React.FC = () => {
	const [user, setUser] = useState<UserInfo>();

	useEffect(() => {
		const userData = async () => {
			const data = await getUserData();
			if (data) {
				setUser(data);
			}
		};
		userData();
	}, []);

	return (
		<>
			<Header />
			<div>
				<Sidebar />
				<ResizablePanelGroup direction="horizontal">
					{/* Dashboard content */}
					<ResizablePanel>
						<div className="min-h-screen w-auto ms-12 lg:ms-44 py-6 px-4 lg:p-10 border-primary/30">
							<div className="my-10 space-y-6">
								<div className="">
									<Alert variant={"info"}>
										<Info className="h-4 w-4" />
										<AlertTitle>Version 0.1.0</AlertTitle>
										<AlertDescription>Certaines fonctionnalités sont en cours de developpement.</AlertDescription>
									</Alert>
								</div>
								<div className="">
									{/* Quick Actions */}
									<QuickActions role={String(user?.usertype)} />
								</div>

								{/* Next Meet */}
								<div className="flex flex-col gap-1 py-3">
									<NextMeetsGroup />
								</div>

								{/* Previous Meet */}
								<div className="flex flex-col gap-1 py-3">
									<LastMeetsGroup />
								</div>

								{/* Previous Meet */}
								{/* <div className="flex flex-col gap-1 py-3 lg:w-1/2">
                    <LastFilesList files={files} />
                  </div> */}
							</div>
						</div>
					</ResizablePanel>
					<ResizableHandle />
					{/* Chat content */}
					{/* <ResizablePanel defaultSize={15} minSize={10} className="hidden xl:block">
						<Chat />
					</ResizablePanel> */}
				</ResizablePanelGroup>
			</div>
		</>
	);
};
