import { Header } from "@/components/header/Header";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { getAllPro } from "@/api/prosService";
import { useEffect, useState } from "react";
import { HiMapPin, HiUserCircle } from "react-icons/hi2";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useNavigate } from "react-router-dom";
import { Pagination } from "flowbite-react";
import { isUserAuthentificated } from "@/lib/data";
import { customPagination } from "@/lib/theme";

export const SearchPros: React.FC = () => {
	const navigate = useNavigate();

	const [pros, setPros] = useState<Pro[]>([]);

	// State of search value
	const [searchResults, setSearchResults] = useState("");
	const [searchBy, setSearchBy] = useState("");

	// Value in local storage
	// const searchValueString = localStorage.getItem("search value");
	// const searchValue = searchValueString && JSON.parse(searchValueString);

	const prosResult = pros.filter((pro) => {
		if (searchBy === "Nom") {
			return pro.firstname.toLowerCase().includes(searchResults.toLowerCase()) || pro.lastname.toLowerCase().includes(searchResults.toLowerCase());
		} else if (searchBy === "Lieu") {
			return (pro.adress && pro.adress.toLowerCase().includes(searchResults.toLowerCase())) || (pro.city && pro.city.toLowerCase().includes(searchResults.toLowerCase())) || (pro.country && pro.country.toLowerCase().includes(searchResults.toLowerCase()));
		} else if (searchBy === "Profession") {
			return pro.speciality && pro.speciality.toLowerCase().includes(searchResults.toLowerCase());
		} else {
			return (
				pro.firstname.toLowerCase().includes(searchResults.toLowerCase()) ||
				pro.lastname.toLowerCase().includes(searchResults.toLowerCase()) ||
				(pro.speciality && pro.speciality.toLowerCase().includes(searchResults.toLowerCase())) ||
				(pro.adress && pro.adress.toLowerCase().includes(searchResults.toLowerCase())) ||
				(pro.city && pro.city.toLowerCase().includes(searchResults.toLowerCase())) ||
				(pro.country && pro.country.toLowerCase().includes(searchResults.toLowerCase()))
			);
		}
	});

	// Pagination states
	const [itemsPerPage] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const onPageChange = (page: number) => setCurrentPage(page); // Change current page

	// Displaying in current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const prosSliced = prosResult ? prosResult.slice(startIndex, endIndex) : [];

	useEffect(() => {
		const fetchPros = async () => {
			setPros(await getAllPro());
		};
		fetchPros().catch((e) => console.log(e));
	}, []);

	return (
		<>
			<Header view="client" />

			{/* container */}
			<div className="flex flex-col md:flex-row bg-primary px-5 md:px-32 pt-40 pb-10 gap-2 md:gap-0 transition-all">
				<Input
					value={searchResults}
					placeholder={searchBy === "Nom" ? "John Doe, Jean Dupont..." : searchBy === "Lieu" ? "Lyon, Paris, Marseille..." : searchBy === "Profession" ? "Avocat, Juge, Huissier de justice" : "Recherchez un professionnel"}
					className="relative z-[900] py-6 "
					onChange={(e) => setSearchResults(e.target.value)}
				/>
				<div className="md:absolute z-[900] right-24 max-md:self-center max-md:w-full">
					{/* Search by */}

					<Select value={searchBy} onValueChange={(e) => setSearchBy(e)} defaultValue="Tout">
						<SelectTrigger className={`md:rounded py-6`}>
							<SelectValue placeholder="Recherchez par..." defaultValue={searchBy} />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectItem defaultChecked value={"Tout"}>
									Tout
								</SelectItem>
								<SelectItem value={"Nom"}>Nom</SelectItem>
								<SelectItem value={"Lieu"}>Lieu</SelectItem>
								<SelectItem value={"Profession"}>Profession</SelectItem>
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="p-5 md:p-12">
				{prosSliced && prosSliced.length ? (
					<div className="md:px-24 py-5">
						<p className="text-dark-title font-semibold max-md:self-center"> {pros.length} resultats </p>
					</div>
				) : (
					<div className="p-10">
						<p className="text-center font-bold">Aucun professionnel trouvé</p>
						<p className="text-center">Vérifiez que les informations de votre recherche sont correctes.</p>
					</div>
				)}
				<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 px-2 md:px-24">
					{pros &&
						prosSliced.map((pro, key) => (
							<Card className="border-muted flex flex-col md:flex-row items-center transition-all hover:border-primary/50 py-3" key={key}>
								<CardHeader className=" p-2">
									{pro.profilePicture ? <img src={pro.profilePicture} alt={pro.profilePicture} className="self-center object-cover object-top w-24 h-24 lg:w-30 lg:h-30 rounded-full" /> : <HiUserCircle className="self-center object-cover object-top w-24 h-24 lg:w-30 lg:h-30 rounded-full" />}
								</CardHeader>
								<CardContent>
									<div className="flex flex-col gap-3">
										<div>
											<div className="flex gap-1.5">
												<p className="text-primary font-semibold">{pro.lastname}</p>
												<p className="text-primary font-semibold">{pro.firstname}</p>
											</div>
											<div>
												<p className="text-secondary text-lg font-semibold">{pro.speciality ? pro.speciality : "Non renseigne"}</p>
											</div>
										</div>
										<div>
											{/* <div className="flex items-center gap-1 text-dark-title text-sm">
												<span className="font-semibold">{pro.adress || pro.zip ? `${pro.adress}, ${pro.zip}` : "Inconnu"}</span>
											</div> */}
											{pro.city && pro.country && (
												<div className="flex items-center gap-1 text-dark-title text-sm">
													<HiMapPin />
													<span className="font-semibold">
														{pro.city}, {pro.country}
													</span>
												</div>
											)}
										</div>
										<div className="flex gap-2">
											<Button className="text-xs" size={"sm"} onClick={() => (isUserAuthentificated() ? navigate("/dashboard") : navigate("/login"))}>
												Prendre Rendez-vous
											</Button>
											<Button className="text-xs" size={"sm"} variant={"outline"} onClick={() => (window.location.href = `/pros/${pro.id}`)}>
												Voir
											</Button>
										</div>
									</div>
								</CardContent>
							</Card>
						))}
				</div>
				<div className="min-w-full pt-20 flex justify-center">
					<Pagination className=" " theme={customPagination} currentPage={currentPage} totalPages={Math.ceil(prosResult.length / itemsPerPage)} onPageChange={onPageChange} showIcons></Pagination>
				</div>
			</div>
		</>
	);
};
