// import { getUserData } from "@/api/userService";
// import { useState, useEffect } from "react";
import { AllProsDirectory } from "./AllProsDirectory";
import { Sidebar } from "../sidebar/Sidebar";

export const Directory = () => {
	// const [user, setUser] = useState<UserInfo>();

	// useEffect(() => {
	// 	const fetchUser = async () => {
	// 		const data = await getUserData();
	// 		setUser(data);
	// 	};
	// 	fetchUser();
	// }, []);

	return (
		<>
			<Sidebar />
			<div className="min-h-screen w-auto ms-12 lg:ms-44 border-primary/30">
				<AllProsDirectory />
			</div>
		</>
	);
};
