import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logoWhite from "@/assets/lc-logo-light.png";
import logoBlack from "@/assets/lc-logo-dark.png";
import { HiMenu, HiOutlineX } from "react-icons/hi";
import { Button } from "../ui/button";
import { HeaderProps } from "@/types/props";
import Cookies from "js-cookie";
import { Link } from "react-scroll";
import { Separator } from "../ui/separator";

export const Header: React.FC<HeaderProps> = ({ view }) => {
	const [opened, setOpened] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const token = Cookies.get("token");

	const changeNav = `${isScrolled ? "backdrop-blur-lg bg-light/70 fixed top-0 shadow px-4 xl:px-64 pb-5 lg:pb-0 rounded-lg lg:rounded-none" : "top-4"}`;

	const handleOpen = () => {
		setOpened(true);
	};

	const handleClose = () => {
		setOpened(false);
	};

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const threshold = 0;

			if (scrollPosition > threshold) {
				setIsScrolled(true);
			} else if (scrollPosition == 0) {
				setIsScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<div className={`fixed flex flex-col md:flex-row justify-between md:items-center w-full px-4 md:px-10 py-3 md:py-0 transition-all duration-300 z-[998] ${changeNav}`}>
				<div className="w-fit">
					<NavLink to={"/"}>{isScrolled ? <img src={logoBlack} alt="lc-logo-black" className="self-start w-28 lg:w-32" /> : <img src={logoWhite} alt="lc-logo-white" className="w-36 lg:w-40" />}</NavLink>
				</div>

				{/* Box with anchor and button link (bg white on responsive) */}
				<div className={`space-x-2 font-semibold flex flex-col ${opened ? "" : "hidden md:flex"} md:flex-row gap-3 md:gap-1 bg-light md:bg-transparent ps-3 pe-5 py-8 rounded-lg text-primary animate-fade-down mt-6 md:mt-0`}>
					{/* If in landing pro, show "notre outil" */}
					{view === "pro" && (
						<Link className={`md:self-center hover:underline ms-2 pr-4 cursor-pointer hover:text-secondary font-semibold text-nowrap ${isScrolled ? "md:text-primary font-bold" : "md:text-light"}`} to={"our-tool"} spy smooth offset={-50} duration={1000} onClick={() => setOpened(false)}>
							Notre outil
						</Link>
					)}

					{view === "pro" && (
						// To pricing
						<Link className={`md:self-center hover:underline pr-4 cursor-pointer hover:text-secondary font-semibold text-nowrap ${isScrolled ? "md:text-primary font-bold" : "md:text-light"}`} to={"pricing"} spy smooth offset={-50} duration={1000} onClick={() => setOpened(false)}>
							Tarifs
						</Link>
					)}

					{view === "pro" && <Separator className="block md:hidden bg-primary/20 w-full" />}

					<NavLink className={"ring-1 ring-transparent ms-2 py-2 px-4 md:px-6 rounded-lg bg-secondary text-light hover:bg-secondary/90 transition-all duration-300 text-nowrap flex items-center"} to={view === "client" ? "/pro" : "/"}>
						{view === "client" ? "Je suis professionnel" : "Je suis client"}
					</NavLink>
					<NavLink className={`ring-1 ring-primary/50 py-2 px-4 md:px-6 rounded-lg bg-light text-primary hover:bg-light/90 transition-all duration-300 text-nowrap flex items-center`} to={token ? "/dashboard" : "/login"}>
						Se connecter
					</NavLink>
					{view === "client" && (
						<NavLink className={`ring-1 ring-light/10 py-2 px-4 md:px-6 rounded-lg bg-primary/90 text-light ${isScrolled ? "hover:bg-primary/80" : "hover:bg-light/10"} transition-all duration-300 text-nowrap flex items-center`} to={token ? "/dashboard" : "/register"}>
							S'inscrire
						</NavLink>
					)}
				</div>
				<Button onClick={handleOpen} className={`bg-transparent hover:bg-transparent md:hidden w-16 right-0 absolute ${opened ? "hidden" : ""}`}>
					<HiMenu size={32} className={`${isScrolled ? "text-primary" : "text-light"} `} />
				</Button>
				<Button onClick={handleClose} className={`bg-transparent hover:bg-transparent md:hidden w-16 right-0 absolute ${opened ? "" : "hidden"}`}>
					<HiOutlineX size={32} className={`${isScrolled ? "text-primary" : "text-light"} ${opened ? "" : "hidden"}`} />
				</Button>
			</div>
			{/* Version mobile */}
			{/* <div className={`absolute text-primary -translate-y-10 transition-all w-full left-0 ${opened ? "opacity-100 animate-fade-down" : "opacity-0 animate-fade-up"}`}>
          <div className="backdrop-blur-3xl bg-light py-8 px-4 md:hidden shadow rounded-2xl">
            <div className="flex justify-between py-3">
              <NavLink to={"/"}>
                <img src={logoBlack} alt="lc-logo-black" className="w-28 lg:w-32" />
              </NavLink>

              <HiOutlineX size={32} className={`text-primary`} onClick={handleClose} />
            </div>
            <div className="flex flex-col pt-3 gap-2">
              <NavLink className={" ring-1 ring-transparent py-2 px-4 rounded-lg bg-secondary text-light hover:bg-secondary/90 transition-all duration-300"} to={view === "client" ? "/pro" : "/"}>
                {view === "client" ? "Je suis professionnel" : "Je suis client"}
              </NavLink>
              <NavLink className={"ring-1 ring-primary/50 py-2 px-4 rounded-lg bg-light text-primary hover:bg-light/90 transition-all duration-300"} to={token ? "/dashboard" : "/login"}>
                Se connecter
              </NavLink> */}
			{/* If in landing pro, show "notre outil" */}
			{/* {view === "pro" && (
                <NavLink className={`underline text-center pt-5`} to={"#our-tool"}>
                  Notre outil
                </NavLink>
              )}
              <Link className={`underline text-center pt-5`} to={view === "client" ? "/register" : ""} containerId={view === "client" ? "" : "princing"}>
                {view === "client" ? "S'inscrire" : "Tarifs"}
              </Link>
            </div>
          </div>
        </div> */}
		</>
	);
};
