import React, { useEffect, useState } from "react";
import logoLCApp from "@/components/dashboard/images/logo-lc-app.png";
import { NavLink, useLocation } from "react-router-dom";
import { LayoutDashboard, Settings, LogOut, BookText } from "lucide-react";
import { Button } from "../ui/button";
import { getUserData, userLogout } from "@/api/usersService";
import { Tooltip } from "react-tooltip";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

export const Sidebar: React.FC = () => {
	const activeElem = useLocation();

	const isSettingsActive = () => {
		if (activeElem.pathname.startsWith("/settings/")) {
			return "border-s-2 border-s-secondary rounded-sm";
		}
	};

	const styleActive = (path: string) => `${activeElem.pathname == path ? "border-s-2 border-s-secondary rounded-sm" : "rounded-lg hover:bg-light/10"}`;

	const [user, setUser] = useState<UserInfo>();

	useEffect(() => {
		const userData = async () => {
			const data = await getUserData();
			if (data) {
				setUser(data);
			}
		};
		userData();
	}, []);

	const handleLogOut = () => {
		userLogout(user)
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="flex flex-col justify-between gap-10 fixed lg:left-0 z-[999] h-screen text-sm py-5 px-0 bg-primary text-light w-12 lg:w-auto">
			<div className="flex flex-col gap-1 items-center">
				{/* To Logo */}
				<NavLink to={"/dashboard"} className={"pb-10 flex flex-col items-center"}>
					<img src={logoLCApp} alt="logoLCApp" className="w-6 lg:w-8" />
					<p className={`font-semibold text-light text-xs lg:text-sm ms-2`}>beta</p>
				</NavLink>

				{/* To dashboard */}
				<NavLink to={"/dashboard"} className={`flex gap-3 items-center p-3 w-full ${styleActive("/dashboard")}`}>
					<LayoutDashboard size={20} />
					<p className="hidden lg:block">Tableau de bord</p>
				</NavLink>

				{/* To Visio */}
				<NavLink to={"/directory"} className={`flex gap-3 items-center p-3 w-full ${styleActive("/directory")}`}>
					<BookText size={20} />
					<p className="hidden lg:block">Annuaire Juridique</p>
				</NavLink>

				{/* ======= Coming Soon ======= */}
				{/* To Visio */}
				{/* <NavLink to={"/"} className={"flex  gap-3 items-center p-3 rounded-lg hover:bg-light/10 w-full"}>
					<Video size={20} />
					<p className="hidden lg:block">Visio</p>
				</NavLink> */}

				{/* To Agenda */}
				{/* {role === "pro" && (
					<NavLink to={"/"} className={"flex gap-3 items-center p-3 rounded-lg hover:bg-light/10 w-full"}>
						<Calendar size={20} />
						<p className="hidden lg:block">Agenda</p>
					</NavLink>
				)} */}

				{/* To Documents */}
				{/* <NavLink to={"/"} className={"flex  gap-3 items-center p-3 rounded-lg hover:bg-light/10 w-full"}>
					<FileText size={20} />
					<p className="hidden lg:block">Documents</p>
				</NavLink> */}

				{/* To Chat */}
				{/* <NavLink to={"/"} className={"flex  gap-3 items-center p-3 rounded-lg hover:bg-light/10 w-full"}>
					<MessagesSquare size={20} />
					<p className="hidden lg:block">Messagerie</p>
				</NavLink> */}
				{/* ======= Coming Soon ======= */}

				{/* To Settings */}
				<NavLink to={"/settings/user"} className={`flex gap-3 items-center p-3 rounded-lg hover:bg-light/10 w-full ${isSettingsActive()}`}>
					<Settings size={20} />
					<p className="hidden lg:block">Paramètres</p>
				</NavLink>
			</div>

			{/* Bootom */}
			<div className="flex flex-col items-center gap-2">
				<Tooltip id="tooltip-email" />
				{user && (
					<>
						{user && (
							<Button className=" space-x-2 w-10 lg:w-auto" data-tooltip-id="tooltip-email" data-tooltip-content={user.email}>
								<Avatar className="flex justify-center items-center w-8 h-8 lg:w-30 lg:h-30 bg-gray/5 border border-light/20 rounded-full ">
									<AvatarImage src={user.profilePicture} className="rounded-full" />
									<AvatarFallback className="text-sm text-light selection:bg-none hover:cursor-default selection:cursor-default">
										{user.firstname[0].toUpperCase()}
										{user.lastname[0].toUpperCase()}
									</AvatarFallback>
								</Avatar>
								{user && (
									<p className="text-ellipsis truncate overflow-hidden max-w-28 hidden lg:block">
										{user.firstname} {user.lastname}
									</p>
								)}
							</Button>
						)}
						<NavLink to={"/settings/user"} className={"flex gap-2 "}>
							<Settings size={20} />
							<span className="hidden lg:block">Paramètres</span>
						</NavLink>
						<Button className="bg-transparent text-xs hover:underline space-x-1 max-w-40" onClick={handleLogOut}>
							<LogOut size={20} /> <span className="hidden lg:block">Se deconnecter</span>
						</Button>
					</>
				)}
				<p className="text-xs">0.1.0</p>
			</div>
		</div>
	);
};
