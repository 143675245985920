import React, { useEffect, useState } from "react";
import { LogOut, Settings } from "lucide-react";
import { NavLink, useNavigate } from "react-router-dom";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { getUserData, userLogout } from "@/api/usersService";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export const Header: React.FC = () => {
	const [user, setUser] = useState<UserInfo>();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUserData();
			setUser(data);
		};
		fetchUser();
	}, []);

	const handleLogOut = () => {
		userLogout(user);
	};

	return (
		<div className="flex items-center justify-end fixed w-full bg-primary text-light h-12 px-1 gap-6 z-50">
			<div className="flex items-center gap-3 relative right-5">
				<DropdownMenu>
					<DropdownMenuTrigger>
						{user && (
							<Avatar className="flex justify-center items-center w-8 h-8 lg:w-30 lg:h-30 bg-gray/5 border border-light/20 rounded-full ">
								<AvatarImage src={user.profilePicture} className="rounded-full" />
								<AvatarFallback className="text-sm text-light selection:bg-none hover:cursor-default selection:cursor-default">
									{user.firstname[0].toUpperCase()}
									{user.lastname[0].toUpperCase()}
								</AvatarFallback>
							</Avatar>
						)}
					</DropdownMenuTrigger>

					<DropdownMenuContent className="bg-light border-primary/20 shadow-none p-1">
						<DropdownMenuLabel className="flex flex-col">
							<span className="text-base">
								{user && user.firstname} {user && user.lastname.toUpperCase()}
							</span>
							<span className="text-primary font-medium">{user && user.email}</span>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuItem className="cursor-pointer hover:bg-gray/20 p-2 rounded-lg" onClick={() => navigate("/settings/user")}>
							Mon Compte
						</DropdownMenuItem>
						<DropdownMenuItem className="flex gap-1 text-red-500 cursor-pointer hover:bg-gray/20 p-2 rounded-lg" onClick={handleLogOut}>
							<LogOut size={20} />
							Se deconnecter
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
				<NavLink to={"/settings/user"}>
					<Settings />
				</NavLink>
			</div>
		</div>
	);
};
