import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { PricingProps } from "@/types/props";
import React from "react";
import { Check } from "lucide-react";
import { Badge } from "../ui/badge";
import { Link } from "react-scroll";

export const PricingCard: React.FC<PricingProps> = ({ formule, desc, price, period, features, best }) => {
  return (
    <div>
      <Card className={`border-0 ${best ? "outline outline-secondary" : "border border-gray"}`}>
        <CardHeader>
          <div className="flex justify-between">
            <p className="font-bold text-secondary">{formule}</p>
            {best && (
              <Badge variant={"outline"} className="text-xs">
                La plus utilise
              </Badge>
            )}
          </div>
          <p>{desc}</p>
        </CardHeader>
        <CardContent>
          <div className="flex items-end gap-2">
            <span className="font-bold text-3xl">{price}€</span>
            <p>/{period}</p>
          </div>
          <div className="py-10">
            <Link to="contact-pro"className="bg-primary rounded-md p-3 text-light truncate text-sm" spy smooth offset={-50} duration={1000}>
            Souscrire un abonnement
            </Link>
          </div>
          <ul>
            {features.map((feature, key) => (
              <li className="flex gap-3 text-lg" key={key}>
                <Check />
                {feature}
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};
