import { Outlet } from "react-router-dom";
import { Footer } from "./components/footer/Footer";
import { isUserAuthentificated } from "./lib/data";
import { Toaster } from "sonner";

function App() {
	return (
		<>
			<Toaster richColors position="top-center" />

			<Outlet />
			{!isUserAuthentificated() && <Footer />}
		</>
	);
}

export default App;
