import React, { useState } from "react";
import logoLcDark from "@/assets/lc-logo-dark.png";
import { Link } from "react-router-dom"; // remettre navlink
import { Quote, ChevronLeftCircle, CaseUpper, FileDigit, AtSign, MoveHorizontal, EyeIcon, EyeOff, ArrowLeft, CheckCircle } from "lucide-react"; // remettre check circle
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { toast, Toaster } from "sonner";
import { HiUser, HiLockClosed, HiOutlineLockClosed } from "react-icons/hi2";
import { userRegister } from "@/api/usersService";

/**
 *
 * Register component
 * _insert a doc here_
 *
 */
export const Register: React.FC = () => {
	/* Steps State */
	const [step, setStep] = useState<number>(1);
	/* For password confirmation */
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	/* State for showing password (true -> show | false -> hide) */
	const [showPassword, setShowPassword] = useState(false);
	/** User added here then post object in API */
	const [user, setUser] = useState<UserRegister>({
		usertype: "",
		gender: "",
		lastname: "",
		firstname: "",
		email: "",
		password: "",
		speciality: "",
	});

	/**  State of password conditions */
	const [checkPassword, setCheckPassword] = useState<checkPassword>({
		capsLetterCheck: false,
		numberCheck: false,
		specialCharCheck: false,
		lengthCheck: false,
	});

	/** Trigger a shake animation if field is empty */

	/** Email regex */
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	/**@returns True if all field in STEP 1 are filled */
	const checkAllFieldStep1 = () => {
		if (user.usertype === "Professionnel") {
			return Boolean(user.usertype && user.speciality) && Boolean(user.gender) && Boolean(user.lastname) && Boolean(user.firstname);
		} else {
			return Boolean(user.usertype) && Boolean(user.gender) && Boolean(user.lastname) && Boolean(user.firstname);
		}
	};

	/**@returns True if all field in STEP 2 are filled */
	const checkAllFieldStep2 = () => {
		return Boolean(user.email) && Boolean(user.password) && Boolean(confirmPassword);
	};

	/**@returns True if all password conditions respected */
	const checkPasswordConditions = () => {
		return checkPassword.capsLetterCheck && checkPassword.numberCheck && checkPassword.specialCharCheck && checkPassword.lengthCheck && checkSamePassword();
	};

	/**@returns True if is correct email */
	const checkEmailRegex = () => {
		return emailRegex.test(user.email);
	};

	/**@returns True if the user.password and confirmPassword are identical */
	const checkSamePassword = () => {
		return confirmPassword === user.password;
	};

	/** @function () Go to the next step */
	const handleNext: () => void = () => {
		if (step === 1) {
			if (checkAllFieldStep1()) {
				setStep((prev) => prev + 1);
			} else {
				// Check all conditions
				if (!checkAllFieldStep1()) {
					toast.error("Erreur", { description: "Un ou plusieurs champs sont vide" });
				}
			}
		} else if (step === 2) {
			if (checkAllFieldStep2()) {
				setStep((prev) => prev + 1);
			} else {
				if (!checkPasswordConditions()) {
					toast.error("Erreur", { description: "Les conditions du mot de passe ne sont pas respectées" });
				}
				if (!checkEmailRegex()) {
					toast.error("Erreur", { description: "L'email indiqué est incorrect" });
				}
				if (!checkSamePassword()) {
					toast.error("Erreur", { description: "Les mots de passe sont différents" });
				}
			}
		} else {
			setStep((prev) => prev + 1);
		}
	};

	/** @function () Back to the prev step */
	const handlePrev: () => void = () => {
		setStep((prev) => prev - 1);
	};

	/** @function () Disabling next button when all conditions are not respected */
	const handleDisabled = () => {
		if (step === 1) {
			if (!checkAllFieldStep1()) {
				return true;
			} else {
				return false;
			}
		}
		if (step === 2) {
			if (!checkAllFieldStep2() || !checkPasswordConditions() || !checkEmailRegex() || !checkSamePassword()) {
				return true;
			} else {
				return false;
			}
		}
	};

	/** Updating password condition (ex: there is a number in field, so numberCheck = true) */
	const handleOnKeyUp = () => {
		const capsLetterCheck = /[A-Z]/.test(user.password);
		const numberCheck = /[0-9]/.test(user.password);
		const lengthCheck = user.password.length >= 8;
		const specialCharCheck = /[!@#$%^&*]/.test(user.password);
		setCheckPassword({
			capsLetterCheck,
			numberCheck,
			lengthCheck,
			specialCharCheck,
		});
	};

	const setProgressPassword = () => {
		const values: Boolean[] = Object.values(checkPassword);
		const newCheck = values.filter((e) => e === true);
		return newCheck.length;
	};

	/** To show password in field */
	const handleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const handleSubmit = async () => {
		userRegister(user)
			.then((response) => {
				console.log(response);
				// window.location.href = "/login";
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<Toaster position="top-center" richColors closeButton />

			{/* Header */}
			<div className="fixed w-screen lg:flex md:justify-between justify-center items-center px-10 py-5 top-0 lg:top-4">
				<Link to={"/"} className=" text-primary lg:text-muted flex gap-1 w-full">
					<ArrowLeft /> Retour a l'accueil
					{/* <img src={logoLcLight} alt="" className="w-36" /> */}
				</Link>
			</div>

			{/* Container */}
			<div className="max-h-screen grid lg:grid-cols-2">
				{/* ===== Left Part ===== */}
				<div className="min-h-screen bg-primary text-light hidden lg:flex items-end">
					{/* Quotes */}
					<div className="flex flex-col gap-3 p-8 text-xl">
						<Quote size={72} strokeWidth={1} className="opacity-30" />
						<p className="font-thin">Cet outil m’a permis de développer de manière très significative mon activité, j’ai pu accroitre ma visibilité ainsi que mon organisation pour des résultats surprenants.</p>
						<p>
							<span className="font-bold">Valérie DUPONT</span> - Avocate
						</p>
					</div>
				</div>

				{/* ===== Right Part ===== */}
				<div className="min-h-screen flex flex-col justify-center items-center gap-5 px-10 md:px-0 w-auto lg:w-4/5 mx-auto">
					<div className=" pb-5">
						<img src={logoLcDark} alt="" className="w-52" />
					</div>
					<div className="text-center">
						<h1 className="text-primary text-2xl font-bold tracking-tighter">Création de votre compte</h1>
						{step === 1 ? (
							<>
								<p className="text-zinc-500 text-center mx-auto">Entrez vos informations personnelles.</p>
							</>
						) : step === 2 ? (
							<>
								<p className="text-zinc-500 text-center mx-auto">Entrez vos identifiants de connexion.</p>
							</>
						) : (
							""
						)}
					</div>
					{/* Steps indicator */}
					<div className="flex  justify-center items-center gap-20">
						{/* Prev */}
						<Button className="" variant={"ghost"} onClick={handlePrev} hidden={step === 3} disabled={step === 1 || step === 3}>
							<ChevronLeftCircle size={32} strokeWidth={1} />
						</Button>

						<div className="flex items-center justify-center gap-24 -translate-x-16">
							<Progress value={step === 2 ? 100 : 0} className="absolute w-40 h-2" />
							{/* icon 1 */}
							<div className={`p-2 rounded-full z-10 ${step >= 1 ? "bg-primary text-light " : "bg-muted "}`}>
								<HiUser size={28} />
							</div>
							{/* icon 2 */}
							<div className={`p-2 rounded-full z-10 ${step >= 2 ? "bg-primary text-light " : "bg-muted text-primary/40"}`}>{step >= 2 ? <HiLockClosed size={28} /> : <HiOutlineLockClosed size={28} />}</div>
							{/* icon 3 */}
							{/* <div className={`p-2 rounded-full z-10 ${step >= 3 ? "bg-primary text-light " : "bg-muted text-primary/40"}`}>{step >= 3 ? <HiCheck size={28} /> : <HiOutlineCheck size={28} />}</div> */}
						</div>
					</div>

					<div className={`flex flex-col gap-4`}>
						{step === 1 && (
							<div className="flex flex-col items-stretch gap-4">
								{/* Gender */}
								<div className="py-2">
									<RadioGroup className="flex justify-around " value={user.gender} onValueChange={(e) => setUser!((prevUser) => ({ ...prevUser, gender: e }))}>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="M" />
											<Label>Monsieur</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="F" />
											<Label>Madame</Label>
										</div>
									</RadioGroup>
								</div>
								{/* Client or Pro */}
								<Select value={user.usertype} onValueChange={(e) => setUser!((prevUser) => ({ ...prevUser, usertype: e, speciality: "" }))}>
									<SelectTrigger className={`w-full `}>
										<SelectValue placeholder="Vous êtes" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											<SelectItem value={"Client"}>Client</SelectItem>
											<SelectItem value={"Professionnel"}>Professionnel</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select>

								{/* if pro */}
								{user.usertype === "Professionnel" && (
									<Select value={user.speciality} onValueChange={(e) => setUser!((prevUser) => ({ ...prevUser, speciality: e }))}>
										<SelectTrigger className={`w-full`}>
											<SelectValue placeholder="Indiquez votre profession" />
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												<SelectItem value={"Avocat"}>Avocat(e)</SelectItem>
												<SelectItem value={"Juge"}>Juge</SelectItem>
												<SelectItem value={"Notaire"}>Notaire</SelectItem>
												<SelectItem value={"Huissier de justice"}>Huissier de justice</SelectItem>
												<SelectItem value={"Juriste"}>Juriste</SelectItem>
											</SelectGroup>
										</SelectContent>
									</Select>
								)}

								{/* Firstame */}
								{/* Lastname */}
								<div className={`flex gap-3 w-full`}>
									<Input placeholder="Prénom" type="text" required value={user.firstname} onChange={(e) => setUser!((prevUser) => ({ ...prevUser, firstname: e.target.value }))} />
									<Input placeholder="Nom" type="text" required value={user.lastname} onChange={(e) => setUser!((prevUser) => ({ ...prevUser, lastname: e.target.value }))} />
								</div>

								{/* Button Next step */}
								<div>
									{/* Button */}
									<Button className="w-full" onClick={handleNext} disabled={handleDisabled()}>
										Suivant
									</Button>
									{/* OU */}
									<div className="flex flex-row justify-center items-center mx-auto">
										<hr className="w-full h-px my-6 border-1 border-gray" />
										<span className="px-8 font-semibold text-[12px] text-zinc-500">OU</span>
										<hr className="w-full h-px my-6 border-1 border-gray" />
									</div>

									{/* Link to login */}
									<Button variant={"outline"} className="w-full" onClick={() => (window.location.href = "/login")}>
										Se connecter
									</Button>
								</div>
							</div>
						)}

						{step === 2 && (
							<div className={`flex flex-col gap-4`}>
								{/* Email */}
								<div className="flex flex-col gap-3 w-full">
									<Input placeholder="nom@exemple.fr" type="email" required value={user.email} onChange={(e) => setUser!((prevUser) => ({ ...prevUser, email: e.target.value }))} />
								</div>

								<div className="flex flex-col gap-3 w-full">
									{/* Pasword */}
									<div className="flex">
										<Input placeholder="Mot de passe" type={showPassword ? "text" : "password"} required value={user.password} onChange={(e) => setUser!((prevUser) => ({ ...prevUser, password: e.target.value }))} onKeyUp={handleOnKeyUp} />
										<Button className="bg-transparent hover:bg-transparent  p-0 " onClick={() => handleShowPassword()}>
											{showPassword ? <EyeOff className="text-zinc-900 -translate-x-6 absolute" size={20} /> : <EyeIcon className="text-zinc-900 -translate-x-6 absolute" size={20} />}
										</Button>
									</div>
								</div>

								{/* Displaying password conditions */}
								<div className={`p-2 rounded border-s-4 border-muted`}>
									<div className="flex flex-col gap-2 my-2 ">
										<small>Robustesse du mot de passe</small>
										{/* Progress bar indicate the strength of password in terms of checkPassword state */}
										<Progress value={setProgressPassword() >= 2 && setProgressPassword() <= 3 ? 40 : setProgressPassword() >= 3 ? 70 : 15} color={setProgressPassword() < 2 ? "bg-red-400" : setProgressPassword() >= 2 && setProgressPassword() <= 3 ? "bg-orange-400" : "bg-emerald-400"} className="h-1.5 " />
									</div>
									{/* Indication of a condition was validate */}
									<div className="grid grid-cols-2 space-y-px">
										<div className={`flex gap-3 transition-all duration-200 delay-0 text-[14px] ${!checkPassword.capsLetterCheck ? "text-red-400" : "text-emerald-400"}`}>
											<CaseUpper size={18} />
											<p>1 Majuscule</p>
										</div>
										<div className={`flex gap-3 transition-all duration-200 delay-0 text-[14px] ${!checkPassword.numberCheck ? "text-red-400" : "text-emerald-400"}`}>
											<FileDigit size={18} />
											<p>1 chiffre</p>
										</div>
										<div className={`flex gap-3 transition-all duration-200 delay-0 text-[14px] ${!checkPassword.specialCharCheck ? "text-red-400" : "text-emerald-400"}`}>
											<AtSign size={18} />
											<p>1 caractere speciale</p>
										</div>
										<div className={`flex gap-3 transition-all duration-200 delay-0 text-[14px] ${!checkPassword.lengthCheck ? "text-red-400" : "text-green-400"}`}>
											<MoveHorizontal size={18} />
											<p>8 caractere minimum</p>
										</div>
									</div>
								</div>
								{/* Confirm Pasword */}
								<div className="flex flex-col gap-3 w-full">
									<Input
										className={step === 2 && confirmPassword !== user.password ? "border border-red-500" : "border border-green-500"}
										placeholder="Confirmation du mot de passe"
										type={showPassword ? "text" : "password"}
										required
										value={confirmPassword}
										onChange={(e) => setConfirmPassword!(e.target.value)}
									/>
								</div>

								<div>
									{/* Button submit*/}
									<Button className="w-full" onClick={handleSubmit} disabled={handleDisabled()}>
										Suivant
									</Button>
									{/* OU */}
									<div className="flex flex-row justify-center items-center mx-auto">
										<hr className="w-full h-px my-6 border-1 border-gray" />
										<span className="px-8 font-semibold text-[12px] text-zinc-500">OU</span>
										<hr className="w-full h-px my-6 border-1 border-gray" />
									</div>

									{/* Link to login */}
									<Button variant={"outline"} className="w-full" onClick={() => (window.location.href = "/login")}>
										Se connecter
									</Button>
								</div>
							</div>
						)}

						{step === 3 && (
							<div className="flex flex-col items-center text-center gap-5">
								<p className="text-xl font-semibold">Inscription terminee !</p>
								<CheckCircle size={120} className="" />
								<Button onClick={() => {}}>Acceder a mon tableau de bord</Button>
								<p>Vous pouvez desormais vous connecter a votre compte</p>
								{step === 3 && ""}
							</div>
						)}

						{/* Button FranceConnect */}
						{/* <Button className="flex gap-5 px-12 py-8 ">
								<img src={franceConnect} alt="" />
								<div className="flex flex-col">
									<span className="font-normal">S'identifier avec</span>
									<span className="font-bold">FranceConnect</span>
								</div>
							</Button> */}

						{/* Accept CGU and Privacy Policy */}
						{/* <div className="w-full">
								<p className="text-gray text-center">
									En cliquant sur suivant, vous acceptez
									<Link to={"/"} className="underline px-1">
										les conditions générales d’utilisation
									</Link>
									et la
									<Link to={"/"} className="underline px-1">
										politique de confidentialitée.
									</Link>
								</p>
							</div> */}
					</div>
					{/* {step === 2 && <Step2 />} */}
					{/* {step === 3 && <Step3 />} */}
				</div>
			</div>
		</>
	);
};

// const Step2: React.FC<StepsProps> = ({ handleNext, step }) => {
// 	/** OTP state (value of input) */
// 	const [otp, setOtp] = useState("");

// 	/** CSS Style For the OTP Input */
// 	const otpStyle = { margin: "12px", padding: "1em 0" };

// 	return (
// 		<div className={`flex flex-col items-center gap-4`}>
// 			<div className="flex flex-col justify-center items-center text-center gap-4">
// 				<Mail size={120} strokeWidth={0.5} />
// 				<h1 className="text-primary text-2xl font-bold">Vérifier votre e-mail</h1>
// 				<p className="text-gray text-lg">Nous vous remercions de bien vouloir indiquer le code à 6 chiffres envoyer sur l’adresse e-mail indiquée.</p>
// 			</div>
// 			<div className={`flex flex-col items-center gap-5 ${step != 2 ? "hidden" : ""}`}>
// 				{/* Otp Input to confirm code email */}
// 				<OtpInput value={otp} onChange={setOtp} numInputs={6} renderSeparator={<span>-</span>} renderInput={(props) => <Input {...props} style={otpStyle} className="w-14 h-20 text-center text-4xl z-50 text-gray font-bold focus:ring-0 focus-visible:border" />} />

// 				{/* Resend code if don't receive */}
// 				<Link to={"/"} className="text-gray underline space-y-12">
// 					renvoyer le code
// 				</Link>

// 				{/* Button */}
// 				<Button className="w-full" onClick={handleNext} disabled={otp.length < 6 ? true : false}>
// 					Suivant
// 				</Button>
// 			</div>
// 		</div>
// 	);
// };

// const Step3: React.FC<StepsProps> = ({ step }) => {
// 	const timeRedirect = 5000; // Temps total en millisecondes
// 	const [countdown, setCountdown] = useState<number>(0);

// 	const handleProgress = () => {
// 		while (countdown <= 100) {
// 			setCountdown((prevCountdown) => prevCountdown + 1);
// 		}
// 	};

// 	console.log(step, timeRedirect, handleProgress); // beacause never read

// 	return (
// 		<div className={`flex flex-col items-center py-5 gap-10 `}>
// 			<CheckCircle size={120} strokeWidth={0.5} />
// 			<Button className="text-light">
// 				Redirection
// 			</Button>
// 		</div>
// 	);
// };
