import React from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

export const FAQSection: React.FC = () => {
	const faqQuestions: Array<{ question: string; answer: string }> = [
		{
			question: "Comment fonctionne l'application ?",
			answer: "L'application fonctionne en offrant un annuaire de professionnels du droit, tels que des avocats spécialisés dans divers domaines juridiques et des conseillers juridiques. Les utilisateurs peuvent parcourir cet annuaire pour trouver un professionnel correspondant à leurs besoins spécifiques. Une fois qu'ils ont sélectionné un professionnel, ils peuvent prendre rendez-vous directement via l'application pour une consultation en ligne.",
		},
		{
			question: "Comment puis-je prendre rendez-vous sur LegalConnect ?",
			answer: "Pour prendre rendez-vous, il vous suffit de consulter l'annuaire juridique pour rechercher un professionnel. Connectez-vous ensuite à votre compte LegalConnect afin de planifier et d'avoir une vue d'ensemble sur vos rendez-vous. ",
		},
		{
			question: "Comment fonctionne la teleconsultation juridique.",
			answer: "Pour protéger vos données personnelles et juridiques, notre application utilise des mesures de sécurité avancées, y compris le cryptage des données et des protocoles de sécurité conformes aux normes de l'industrie. Seuls les professionnels autorisés ont accès à vos informations, et ils sont tenus par des obligations strictes de confidentialité.",
		},
	];

	return (
		<>
			<div className="text-center">
				<p className={`font-bold text-secondary uppercase`}>Notre F.A.Q</p>
				<h1 className="text-3xl xl:text-4xl font-bold text-dark-title">Les questions fréquemment posées.</h1>
			</div>

			<div className="flex justify-center ">
				<Accordion type="single" collapsible className="w-full sm:w-11/12 lg:w-5/6 2xl:w-3/5">
					{faqQuestions.map((faq, key) => (
						<AccordionItem value={`item-${key}`} className="border-gray" key={key}>
							<AccordionTrigger>{faq.question}</AccordionTrigger>
							<AccordionContent>{faq.answer}</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
			</div>
		</>
	);
};
