import { FaRegAddressCard } from "react-icons/fa6";
// import { BsGear } from "react-icons/bs";
import { NavLink, useLocation } from "react-router-dom";
import { getUserData } from "@/api/usersService";
import { useState, useEffect } from "react";
import { Building } from "lucide-react";

export const SidebarSettings = () => {
	const activeElem = useLocation();
	const styleActive = (path: string) => `${activeElem.pathname == path ? "bg-primary text-light " : "hover:bg-primary/5"}`;
	const [user, setUser] = useState<UserInfo>();

	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUserData();
			setUser(data);
		};
		fetchUser();
	}, []);

	return (
		<div className="fixed flex flex-col gap-6 min-h-screen pt-4 w-14 lg:w-80 translate-x-[3.2rem] lg:translate-x-[11.5rem] bg-primary/5 border-t-0 border-r border-r-primary/30 mb-10 lg:p-3">
			<p className="text-lg font-semibold self-center hidden lg:block">Paramètres</p>

			<div className="flex flex-col gap-2">
				<NavLink to={"/settings/user"} className={`flex gap-2 items-center px-2 py-3 w-10 lg:w-auto self-center lg:self-auto rounded-md text-sm ${styleActive("/settings/user")}`}>
					<FaRegAddressCard className="w-6 h-6" />
					<p className="hidden lg:block">Informations</p>
				</NavLink>
				{user?.usertype === "Professionnel" && (
					<NavLink to={"/settings/office"} className={`flex gap-2 items-center px-2 py-3 w-10 lg:w-auto self-center lg:self-auto rounded-md text-sm ${styleActive("/settings/office")}`}>
						<Building className="w-6 h-6" />
						<p className="hidden lg:block">Gestion du Cabinet</p>
					</NavLink>
				)}
				{/* <NavLink to={"/settings/account"} className={`flex gap-2 items-center px-2 py-3 rounded-md text-sm ${styleActive("/settings/account")}`}>
					<BsGear className="w-5 h-5" />
					<p>Compte</p>
				</NavLink> */}
				{/* <NavLink to={"/"} className="flex gap-2 items-center hover:bg-primary/10 px-2 py-3 rounded-md text-sm">
					<FaRegAddressCard className="w-5 h-5" />
					<p>Informations</p>
				</NavLink> */}
			</div>
		</div>
	);
};
