import axios from "axios";
import { toast } from "sonner";
import Cookies from "js-cookie";

const baseUrl: string = "https://api.legalconnect.nadir-gharbi.fr/api/v1" as const;
// const localUrl: string = "http://localhost:3333/api/v1" as const;

export const userLogin = async (data: UserLogin) => {
	try {
		const response = await axios.post(`${baseUrl}/auth/login`, data);
		toast.success("Connexion reussie");
		Cookies.set("user_token", response.data.token.token, { expires: 1 }); // expires in 1 days
		window.location.href = "/dashboard";
	} catch (error) {
		toast.error("Vos identifiants sont incorrect. Veuillez reessayez");
		console.log(error);
	}
};

export const userRegister = async (data: UserRegister) => {
	try {
		const response = await axios.post(`${baseUrl}/auth/register`, data);
		toast.success("Votre compte a été créé avec succès !");
		window.location.href = "/login";
		console.log(response);

		// window.location.href = "/dashboard";
	} catch (error: any) {
		if (error.response.data.errors[0].message === "The email has already been taken") {
			toast.error("Cette adresse e-mail est déjà prise");
		} else {
			toast.error("Une erreur s'est produite lors de l'inscription.");
		}
		// throw new Error(error);
		console.log(error);
	}
};

export const userLogout = async (data: any) => {
	try {
		const token = Cookies.get("user_token");
		await axios.post(`${baseUrl}/auth/logout`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		Cookies.remove("user_token");
		window.location.href = "/login";
	} catch (error) {
		toast.error("Oops, une erreur s'est produite. Veuillez reessayez");
		console.log(error);
	}
};

export const getUserData = async () => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.get(`${baseUrl}/auth/user`, {
			headers: {
				Authorization: `Bearer ${token}`, // ajout du token dans le header
			},
		});
		return response.data;
	} catch (error: any) {
		console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
		throw new Error(error);
	}
};

// For one user
export const getUserById = async (id: number) => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.get(`${baseUrl}/user/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`, // ajout du token dans le header
			},
		});
		return response.data;
	} catch (error: any) {
		throw new Error(error);
	}
};

// Update info of user
export const updateUser = async (data: UserUpdate) => {
	try {
		const token = Cookies.get("user_token");
		const response = await axios.put(`${baseUrl}/auth/user/update`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		toast.success("Vos informations ont été modifiées avec succès !");
		window.location.reload()
		return response.data;
	} catch (error) {
		toast.error("Une erreur s'est produite lors de la modification de vos informations. Veuillez réessayer en veillant à remplir tous les champs requis.");
		console.error(error);
	}
};
